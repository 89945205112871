import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'
import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader'
import Sidebar from './Sidebar'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getEnquiry, deleteEnquiry, clearErrors } from '../../actions/userActions'
import { DELETE_INQUIRY_RESET } from '../../constants/userConstants'
import "jspdf-autotable";
import Dialog from "../../Dialog";
import { CSVLink } from 'react-csv';

const ProductEnquiry = () => {
    const navigate = useNavigate();
    const alert = useAlert();
    const dispatch = useDispatch();
    const { loading, error, productEnquiry } = useSelector(state => state.productEnquiry);
    const { isDeleted } = useSelector(state => state.enquiry);
    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        //Update
        nameProduct: ""
    });

    const handleDialog = (message, isLoading, nameProduct, id) => {
        setDialog({
            message,
            isLoading,
            //Update
            nameProduct,
            id
        });
    };


    useEffect(() => {
        dispatch(getEnquiry());
        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }

        if (isDeleted) {
            alert.success("Enquiry has been deleted ");
            navigate('/admin/enquiry');
            dispatch({ type: DELETE_INQUIRY_RESET })
        }

    }, [dispatch, navigate, alert, isDeleted, error])

    const csvData = JSON.parse(JSON.stringify(productEnquiry ? productEnquiry : []));

    const setEnquiry = () => {
        const data = {
            columns: [
                {
                    label: 'Date',
                    field: 'date',
                    sort: 'asc',
                    maxWidth: 400,
                    minWidth: 140,
                    width: 140,
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    maxWidth: 400,
                    minWidth: 140,
                    width: 200,
                },
                {
                    label: 'Phone',
                    field: 'mobile',
                    sort: 'asc',
                    maxWidth: 400,
                    minWidth: 140,
                    width: 140,
                },
                {
                    label: 'Product Name',
                    field: 'pname',
                    sort: 'asc',
                    maxWidth: 400,
                    minWidth: 140,
                    width: 200,
                },
                {
                    label: 'Qty',
                    field: 'qty',
                    sort: 'asc',
                    maxWidth: 400,
                    minWidth: 140,
                    width: 100,
                },

                {
                    label: 'Message',
                    field: 'messages',
                    sort: 'asc',
                    maxWidth: 400,
                    minWidth: 140,
                    width: 200,
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                    maxWidth: 400,
                    minWidth: 140,
                    width: 250,
                },
                {
                    label: 'User Name',
                    field: 'uname',
                    sort: 'asc',
                    maxWidth: 400,
                    minWidth: 140,
                    width: 100,
                },
                {
                    label: 'User Id',
                    field: 'uid',
                    sort: 'asc',
                    maxWidth: 400,
                    minWidth: 140,
                    width: 100,
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    maxWidth: 400,
                    minWidth: 140,
                    width: 100,
                },
            ],
            rows: []
        }
        productEnquiry && productEnquiry?.forEach(inq => {
            const EID = Buffer.from(`${inq.id}`, 'binary').toString('base64');
            data.rows.push({
                date: new Date(inq.created_at).toLocaleDateString('en-IN'),
                name: inq.name,
                email: inq.email,
                mobile: inq.mobile,
                pname: <Link class="proda" to={`/product/${EID}`} target="_blank">{inq.productName}</Link>,
                qty: inq.quantity,
                messages: inq.message,
                actions: <Fragment>
                    <button className="btn btn-danger py-1 px-2 ml-2" onClick={() => deleteInquiryHandler(inq.id, inq.name)} >
                        <i className="fa fa-trash"></i>
                    </button>
                </Fragment>,
                uname: <Link class="proda" to={`/admin/users`} target="_blank">{inq.user_name}</Link>,
                uid: <Link class="proda" to={`/admin/users`} target="_blank">{Buffer.from(`${inq.user_id}`, 'binary').toString('base64')}</Link>,
            })
        })

        return data;
    }
    const deleteInquiryHandler = (id, name) => {
        handleDialog("Are you sure you want to delete enquiry?", true, name, id);
    }

    const areUSureDelete = (choose) => {
        if (choose) {
            dispatch(deleteEnquiry(dialog.id))
            handleDialog("", false);
        } else {
            handleDialog("", false);
        }
    }

    const [list, setList] = useState({ display: "block" });
    const hide = () => {
        if (list.display === "block") {
            setList({ display: "none" })
        } else {
            setList({ display: "block" })
        }
    }

    return (
        <Fragment>
            <MetaData title={'All enquiry'} />
            <div className="row">
                <div style={list} className="col-12 col-md-2">
                    <Sidebar />
                </div>
                <div className={`${list.display === 'block' ? "col-12 col-md-10" : "col-12 col-md-12"}`}>
                    <Fragment>
                        <div className='my-4'></div>
                        <button className="mt-3 proda menuorder" onClick={hide}><i className="fa fa-bars" aria-hidden="true"></i></button>
                        <h1 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-2" >Product Enquiry</h1>
                        <h5 style={{ display: 'inline' }} className="marginleft mr-2 mt-5 px-md-2"> <CSVLink className='menuorder' data={csvData}
                            filename={"Enquiry.csv"} target="_blank" >Download</CSVLink></h5>
                        <br />
                        <br />
                        {loading ? <Loader /> : (
                            <MDBDataTable
                                data={setEnquiry()}
                                className="cust-table px-3"
                                bordered
                                striped
                                hover scrollX
                                exportToCSV
                            />
                        )}
                    </Fragment>
                    {/* <CSVButton  >Download me</CSVButton> */}
                </div>
                {dialog.isLoading && (
                    <Dialog
                        //Update
                        nameProduct={dialog.nameProduct}
                        onDialog={areUSureDelete}
                        message={dialog.message}
                        id={dialog.id}
                    />
                )}

            </div>
        </Fragment>
    )
}

export default ProductEnquiry
