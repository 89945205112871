import React from 'react';
import { Link } from "react-router-dom";


export default function Nav() {

    // JavaScript for toggling submenu on mobile
    document.addEventListener("DOMContentLoaded", function () {
        var menuItems = document.querySelectorAll(".nav.sidebar1 .menu > li");

        menuItems.forEach(function (menuItem) {
            menuItem.addEventListener("click", function (event) {
                if (window.innerWidth <= 768) {
                    var submenu = this.querySelector(".megadrop");
                    if (submenu.style.display === "block") {
                        submenu.style.display = "none";
                    } else {
                        submenu.style.display = "block";
                    }
                    event.stopPropagation();
                }
            });
        });

        // Close submenu when clicking outside the menu
        document.addEventListener("click", function (event) {
            if (window.innerWidth <= 768) {
                if (!event.target.closest(".nav.sidebar1 .menu")) {
                    document.querySelectorAll(".nav.sidebar1 .menu .megadrop").forEach(function (submenu) {
                        submenu.style.display = "none";
                    });
                }
            }
        });
    });


    return (
        <div className='nav sidebar1 bg-white'>
            <ul className="menu">
                <li>
                    <h5 className='mn'>Explore Categories</h5>
                    <a href="#"><img src="/images/power-tools.jpg" alt="power-tools" width="30" height="30" />&nbsp; Industrial Tools & Machinery</a>
                    <div className="megadrop">
                        <div className="col">
                            <h3><a className='menu-head'>Tools</a></h3>
                            <ul>
                                <li> <Link to={{ pathname: "/category/Hammer" }} >Hammer</Link>
                                </li>
                                <li><Link to={{ pathname: "/category/Industrial Tools & Machinery" }}>Industrial Tools & Machinery</Link>
                                </li>
                                <li><Link to={{ pathname: "/category/Multi Tool sets" }}>Multi Tool sets</Link>
                                </li>
                                <li><Link to={{ pathname: "/category/Plier" }}>Plier</Link>
                                </li>
                                <li><Link to={{ pathname: "/category/Spanners set" }}>Spanners set</Link>
                                </li>
                            </ul>
                        </div>

                    </div>
                </li>

                <li> <a href="#"><img src="/images/annul-cutter.jpg" alt="drill" width="30" height="30" />&nbsp; Cutting Tools</a>
                    <div className="megadrop">
                        <div className="megadrop">
                            <div className="col">
                                <h3><a className='menu-head'>Cutting Tools</a></h3>
                                <ul>
                                    <li><Link to={{ pathname: "/category/Drill Driver" }}>Drill Driver</Link>
                                    </li>
                                    <li><Link to={{ pathname: "/category/Drilling Machine" }}>Drilling Machine</Link>
                                    </li>
                                    <li><Link to={{ pathname: "/category/Hand Saw" }}>Hand Saw</Link>
                                    </li>
                                    <li><Link to={{ pathname: "/category/Snips" }}>Snips</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li> <a href="#"><img src="/images/abrasive.jpg" alt="Abrasive" width="30" height="30" />&nbsp; Abrasive</a>
                    <div className="megadrop">
                        <div className="megadrop">
                            <div className="col">
                                <h3><a className='menu-head'> Abrasive</a></h3>
                                <ul>
                                    <li><Link to={{ pathname: "/category/Angle Grinder" }}>Angle Grinder</Link></li>
                                    <li><Link to={{ pathname: "/category/Cutting Whee" }}>Cutting Whee</Link></li>
                                    <li><Link to={{ pathname: "/category/Accessories " }}>Accessories </Link></li>
                                    <li><Link to={{ pathname: "/category/Files" }}>Files</Link></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li> <a href="#"><img src="/images/electric.jpg" alt="electric" width="30" height="30" />&nbsp; Electrical & Equipments</a>
                    <div className="megadrop">
                        <div className="megadrop">
                            <div className="col">
                                <h3><a className='menu-head'>Electrical & Equipment</a></h3>
                                <ul>
                                    <li><Link to={{ pathname: "/category/Laser measuring Device" }}>Laser measuring Device</Link>
                                    </li>
                                    <li><Link to={{ pathname: "/category/Speciality Tools" }}>Speciality Tools</Link>
                                    </li>
                                    <li><Link to={{ pathname: "/category/Socket" }}>Socket</Link>
                                    </li>
                                    <li><Link to={{ pathname: "/category/Measuring & Layout" }}>Measuring & Layout</Link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a href="#"><img src="/images/safety.jpg" alt="Safetty" width="30" height="30" />&nbsp;Safety Equipments</a>
                    <div className="megadrop">
                        <div className="megadrop">
                            <div className="col">
                                <h3><a className='menu-head'> Safety Equipments</a></h3>
                                <ul>
                                    <li><Link to={{ pathname: "/category/Gardening Products" }}>Gardening Products</Link>
                                    </li>
                                    <li><Link to={{ pathname: "/category/Wrench" }}>Wrench</Link>
                                    </li>
                                    <li><Link to={{ pathname: "/category/Storage" }}>Storage</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </li>

            </ul>
        </div>
    );
}
