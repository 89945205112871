import React, { Fragment, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'
import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { myOrders, clearErrors } from '../../actions/orderActions'
import { CANCEL_ORDER_RESET } from '../../constants/orderConstants'

const ListOrders = () => {
    const navigate = useNavigate();
    const alert = useAlert();
    const dispatch = useDispatch();
    const { loading, error, cancelOrders } = useSelector(state => state.myOrders);
    const { isCancel } = useSelector(state => state.cancel)

    // console.log("orders", orders);
    // console.log("isCancel", isCancel);
    // if(orders){
    //     orders.forEach(order=>{
    //     console.log("orders",Buffer.from(`${product.id}`, 'binary').toString('base64'))
    //     console.log("orders",order.quantity)
    //     console.log("orders",order.totalPrice)
    //     console.log("orders",order.orderStatus)}
    //     )}
    useEffect(() => {
        dispatch(myOrders());
        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }
        if (isCancel) {
            alert.success("Product cancel successfully");
            navigate('/orders/me');
            dispatch({ type: CANCEL_ORDER_RESET })
        }
    }, [dispatch, navigate, alert, error, isCancel])

    const setOrders = () => {
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Orders Name',
                    field: 'name',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Reason',
                    field: 'reason',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Order Date',
                    field: 'date',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Price',
                    field: 'price',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Cancel Status',
                    field: 'status',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Cancel Date',
                    field: 'delivered_date',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'asc',
                    width: 100
                }
            ],
            rows: []
        }

        cancelOrders && cancelOrders.forEach(order => {
            if (order.paymentStatus) {
                data.rows.push({
                    name: <Link className='proda' to={`/product/${Buffer.from(order.product_id.toString(), 'binary').toString('base64')}?${encodeURIComponent(order.productName)}`}>{order.productName}</Link>,
                    status: order.orderStatus && String(order.orderStatus).includes('Delivered')
                        ? <p style={{ color: 'green' }}>{order.orderStatus}</p>
                        : <p style={{ color: 'red' }}>{order.orderStatus}</p>,
                    reason: order.reason,
                    price: `${order.sale_price}`,
                    id: order.order_id,
                    date: new Date(String(order.order_date)).toLocaleString('en-IN'),
                    delivered_date: new Date(String(order.delivered_date)).toLocaleString('en-IN'),
                    actions: <Fragment><Link to={`/order/${Buffer.from(`${order.id}`, 'binary').toString('base64')}`} className="btn btn-primary">
                        <i className="fa fa-eye"></i>
                    </Link>
                    </Fragment>
                })
            }
        })
        return data;
    }
    return (
        <Fragment>
            <MetaData title={'My Orders'} />
            <div className='my-5'></div>
            <h4 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-5"><Link to={'/orders/me'} className='menuorder1'>My Orders</Link></h4>
            <h4 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-5"><Link to={'/orders/cancel/me'} className='menuorder'>Cancel Orders</Link></h4>
            <h4 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-5"><Link to={'/orders/return/me'} className='menuorder1'>Return Orders</Link></h4><hr />
            <br />
            {loading ? <Loader /> : (
                <MDBDataTable
                    data={setOrders()}
                    className='cust-table px-md-5 mt-20 mr-5'
                    bordered
                    striped
                    hover scrollX
                />)}
        </Fragment >
    )
}

export default ListOrders
