import React, { useMemo } from 'react';
import '../../App.css';
import { Link } from 'react-router-dom';
import { getDiscountUI } from '../../actions/productActions';

const RelatedItemHome = ({ product }) => {
  const EID = useMemo(() => Buffer.from(`${product.id}`, 'binary').toString('base64'), [product.id]);

  // Memoize the formatted product name to prevent re-computation
  const formattedProductName = useMemo(() => {
    return product.name.length > 48 ? `${product.name.slice(0, 48)}...` : product.name;
  }, [product.name]);

  return (
    <div className="">
      <div className="card card1 p-3 rounded cart-bg">
        <div className="text-center">
          <Link to={`/product/${EID}?${encodeURIComponent(product.name)}`}>
            <img
              className="card-img-top mx-auto"
              alt={product.category}
              src={product.image || '/images/box.png'} // Placeholder image to be replaced with lazy-loaded image
              title={product.name}
              loading="lazy" // Enable lazy loading for browsers that support it
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = '/images/box.png';
              }}
            />
          </Link>
        </div>
        <div className="card-body d-flex flex-column">
          <span className="card-title text-center" style={{ height: '3em', overflow: 'hidden' }}>
            <Link to={`/product/${EID}?${encodeURIComponent(product.name)}`} title={product.name}>
              {formattedProductName}
            </Link>
          </span>

          <p className='text-center'>
            <span className="card-text">
              &#8377;<span className='fw-price'>{Math.round(product.sale_price).toLocaleString('en-IN', { maximumFractionDigits: 2 })}{' '}</span>
            </span>
            {product.discount > 0 && (
              <sub className="text-secondary discount-label">
                /-<del>&#8377;{Math.round(getDiscountUI(product.sale_price, product.discount)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</del>,
                &nbsp;<span className="cart-discount"> {product.discount}% Off</span>
              </sub>
            )}
          </p>

          <div className="ratings mb-1 mt-auto text-center">
            <div className="rating-outer">
              <div
                className="rating-inner"
                style={{ width: `${(product.ratings / 5) * 100}%` }}
              ></div>
            </div>
            <span id="no_of_reviews">({product.numOfReviews} Reviews)</span>
          </div>

          <Link to={`/product/${EID}?${encodeURIComponent(product.name)}`} id="view_btn" className="btn btn-block">
            View Details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default RelatedItemHome;
