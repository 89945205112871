import React, { Fragment, useState, useEffect } from 'react'
import MetaData from '../layout/MetaData'
import { Link, useParams, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { updateProduct, getProductDetails, getDiscount, getGstAmount, allCategory } from '../../actions/productActions'
import { UPDATE_PRODUCT_RESET } from '../../constants/productConstants'
import { useForm } from "react-hook-form";
import FiledView1 from "./FiledView1";
import { property } from './FiledList1'

const UpdateProduct = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState();
    const [original_price, setOriginal_price] = useState();
    const [discount, setDiscount] = useState(0);
    const [gst, setGst] = useState(18);
    const [gstPrice, setGstPrice] = useState(0);
    const [sale_price, setSale_price] = useState(0);
    const [bulk_qty, setBulk_qty] = useState(1);
    const [description, setDescription] = useState('');
    const [categoryName, setCategoryName] = useState();
    const [stock, setStock] = useState();
    const [seller, setSeller] = useState();
    const [images, setImages] = useState([]);
    const [file, setFile] = useState();
    const [oldImages, setOldImages] = useState([]);
    const [imagesPreview, setImagesPreview] = useState([])
    const [productField, setProductField] = useState([])
    const [code, setCode] = useState("");
    const [properties1, setProperties1] = useState("");
    const [value1, setValue1] = useState("");
    const [properties2, setProperties2] = useState("");
    const [value2, setValue2] = useState("");
    const [properties3, setProperties3] = useState("");
    const [value3, setValue3] = useState("");
    const { register, handleSubmit, formState: { errors }, reset, trigger } = useForm();
    const alert = useAlert();
    const dispatch = useDispatch();
    const { category } = useSelector(state => state.allCategory);
    const { error, product, productImages, productDetails } = useSelector(state => state.productDetails)
    const { loading, error: updateError, isUpdated, success } = useSelector(state => state.product);
    const productId = params.id;

    useEffect(() => {
        if (product && product.id !== productId) {
            dispatch(getProductDetails(productId));
        }
        dispatch(allCategory());
        setName(product.name ? product.name : "");
        setOriginal_price(product.original_price ? product.original_price : 0);
        setDiscount(product.discount ? product.discount : 0);
        setGst(product.tax_rate ? product.tax_rate : 0);
        setSale_price(product.sale_price ? product.sale_price : 0);
        setDescription(product.description ? product.description : "");
        setCategoryName(product.category ? product.category : "");
        setSeller(product.seller ? product.seller : "");
        setStock(product.stock ? product.stock : 0);
        setBulk_qty(product.bulk_qty ? product.bulk_qty : 1);
        setOldImages(productImages ? productImages : "")
        setProductField(productDetails ? productDetails : "");
        setCode(product.product_code ? product.product_code : "");
        setProperties1(product.properties1 ? product.properties1 : "");
        setProperties2(product.properties2 ? product.properties2 : "");
        setProperties3(product.properties3 ? product.properties3 : "");
        setValue1(product.value1 ? product.value1 : "");
        setValue2(product.value2 ? product.value2 : "");
        setValue3(product.value3 ? product.value3 : "");


        if (success || isUpdated) {
            navigate('/admin/products');
            alert.success("Product updated successfully");
            dispatch({ type: UPDATE_PRODUCT_RESET })
        }

        if (error) {
            alert.error(error);
        }

        if (updateError) {
            alert.error(updateError);
        }
    }, [dispatch, alert, navigate, error, isUpdated, success, product.id, updateError, productId])



    const submitHandler = (data, e) => {
        const productDetails1 = JSON.stringify(property)
        e.preventDefault();

        const formData = new FormData();
        formData.set('name', name);
        formData.set('original_price', original_price);
        formData.set('discount', discount);
        formData.set("gst", gst);
        formData.set("gstPrice", getGstAmount(getDiscount(original_price, discount), gst) - getDiscount(original_price, discount));
        formData.set("sale_price", getGstAmount(getDiscount(original_price, discount), gst));
        formData.set('description', description);
        formData.set('category', categoryName);
        formData.set('stock', stock);
        formData.set("bulk_qty", bulk_qty);
        formData.set('seller', seller);
        formData.set("productDetails", productDetails1);
        formData.set("code", code);
        formData.set("properties1", properties1);
        formData.set("value1", value1);
        formData.set("properties2", properties2);
        formData.set("value2", value2);
        formData.set("properties3", properties3);
        formData.set("value3", value3);

        // console.log('name', name);
        // console.log('original_price', original_price);
        // console.log('discount', discount);
        // console.log('sale_price', getDiscount(original_price, discount));
        // console.log('description', description);
        // console.log('category', categoryName);

        // console.log('seller', seller);
        // console.log("productDetails", productDetails1);

        // images.forEach(image => {
        //     formData.append('images', image)
        // })
        if (file) {
            for (let i = 0; i < file.length; i++) {
                formData.append(`file`, file[i])
            }
        }

        dispatch(updateProduct(Buffer.from(`${product.id}`, 'binary').toString('base64'), formData));
        e.target.reset();
        reset();
    }

    const onChange = event => {
        const file = event.target.files;
        setFile(file)

        if (event.target.name === 'file') {
            if (file.length === 0) {
                window.alert("Please select a image");
                return false;
            }
            for (let i = 0; i < file.length; i++) {
                if (file[i].type !== "image/png" && file[i].type !== "image/jpg" && file[i].type !== "image/jpeg") {
                    window.alert("File does not support. You must use .png or .jpg ");
                    return false;
                }
                if (file[i].size > 5000000) {
                    window.alert("Please upload a image smaller than 5 MB");
                    return false;
                }
            }
        }

        const files = Array.from(event.target.files)
        setImagesPreview([]);
        setImages([])
        setOldImages([])

        files.forEach(file => {
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setImagesPreview(oldArray => [...oldArray, reader.result])
                    setImages(oldArray => [...oldArray, reader.result])
                }
            }
            reader.readAsDataURL(file)
        })
    }

    return (
        <Fragment>
            <MetaData title={'Update Product'} />
            <div className="row">
                <div className="col-12 col-md-2">
                    <Sidebar />
                </div>
                <div className="col-12 col-md-10">
                    <Fragment>
                        <div className="wrapper my-5">
                            <form key={product && product.id} className="shadow-lg" onSubmit={handleSubmit(submitHandler)} encType='multipart/form-data'>
                                <h1 className="mb-4">Update Product</h1>
                                <div className="form-group">
                                    <label htmlFor="name_field">Product Name</label>
                                    <input
                                        type="text"
                                        id="name_field"
                                        className="form-control"
                                        placeholder="Enter Product Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        required
                                    />
                                </div>

                                <div className='row'>
                                    <div className="col-md-3 form-group">
                                        <label htmlFor="price_field">Price</label>
                                        <input
                                            type="text"
                                            id="price_field"
                                            onWheel={event => event.currentTarget.blur()}
                                            name="price_field"
                                            placeholder="Enter the product price"
                                            className={`form-control ${errors.price_field && "invalid"}`}
                                            {...register("price_field", {
                                                min: {
                                                    value: 1,
                                                    message: "The minimum price should be not less than 1 ",
                                                },
                                                max: {
                                                    value: 1000000,
                                                    message: "The maximum price should be not more than 1000000",
                                                },
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: "Only numbers are allowed",
                                                }
                                            })}
                                            oninvalid={() => {
                                                trigger("price_field");
                                            }}
                                            value={original_price}
                                            onChange={(e) => setOriginal_price(e.target.value)}
                                        />
                                        {errors.price_field && (
                                            <small className="text-danger">
                                                {errors.price_field.message}
                                            </small>
                                        )}
                                    </div>

                                    <div className="col-md-3 form-group">
                                        <label htmlFor="discount_field">Discount in Percentage (%)</label>
                                        <input
                                            type="text"
                                            id="discount_field"
                                            onWheel={event => event.currentTarget.blur()}
                                            name="discount_field"
                                            placeholder="Discount Percentage"
                                            className={`form-control ${errors.discount_field && "invalid"}`}
                                            {...register("discount_field", {
                                                min: {
                                                    value: 0,
                                                    message: "The minimum discount should be not less than 0%",
                                                },
                                                max: {
                                                    value: 99,
                                                    message: "The maximum discount should be not more than 99%",
                                                },
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: "Only numbers are allowed",
                                                }
                                            })}
                                            oninvalid={() => {
                                                trigger("discount_field");
                                            }}
                                            value={discount}
                                            onChange={(e) => setDiscount(e.target.value)}
                                        />
                                        {errors.discount_field && (
                                            <small className="text-danger">{errors.discount_field.message}</small>
                                        )}
                                    </div>

                                    <div className="col-md-3 form-group">
                                        <label htmlFor="discount_field">
                                            Tax Rate GST(%)
                                        </label>
                                        <input
                                            type="text"
                                            onWheel={(event) => event.currentTarget.blur()}
                                            id="gst_field"
                                            name="gst_field"
                                            placeholder="GST Rate"
                                            className={`form-control ${errors.gst_field && "invalid"}`}
                                            {...register("gst_field", {
                                                min: {
                                                    value: 0,
                                                    message: "GST should be not less than 0%",
                                                },
                                                max: {
                                                    value: 99,
                                                    message: "GST should be not more than 99%",
                                                },
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: "Only numbers are allowed",
                                                }
                                            })}
                                            onInvalid={() => {
                                                trigger("gst_field");
                                            }}
                                            value={gst}
                                            onChange={(e) => setGst(e.target.value)}
                                        />
                                        {errors.gst_field && (
                                            <small className="text-danger">{errors.gst_field.message}</small>
                                        )}
                                    </div>

                                    <div className="col-md-3 form-group">
                                        <label htmlFor="discountPrice_field">Discount Price</label>
                                        <input
                                            type="number"
                                            id="discountPrice_field"
                                            onWheel={event => event.currentTarget.blur()}
                                            className="form-control"
                                            value={getGstAmount(getDiscount(original_price, discount), gst)}
                                            onChange={(e) => setSale_price(e.target.value)}
                                            readOnly
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="description_field">Key Features</label>
                                    <textarea
                                        name="description_field"
                                        className="form-control invalid"
                                        placeholder={
                                            "Enter key features of the product...\nFor bullet points use new line"
                                        }
                                        id="description_field" rows="8" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>

                                </div>

                                <div className="form-group">
                                    <label htmlFor="category_field">Select Category <small>(<Link to="/admin/category" target="_blank">Click here to add new categories</Link>)</small></label>
                                    <select
                                        className="form-control"
                                        id="category_field" value={categoryName}
                                        required
                                        onChange={(e) => setCategoryName(e.target.value)}>
                                        <option value="">Please select a category...</option>
                                        {category.map(cat => (
                                            <option key={cat.category} value={cat.category} required>{cat.category}</option>
                                        ))}
                                    </select>
                                </div>
                                <div className='row'>
                                    <div className="col-md-4 form-group">
                                        <label htmlFor="stock_field">Stock</label>
                                        <input
                                            type="text"
                                            id="stock_field"
                                            onWheel={event => event.currentTarget.blur()}
                                            name="stock_field"
                                            placeholder="Enter stock quantity..."
                                            className={`form-control ${errors.stock_field && "invalid"}`}
                                            {...register("stock_field", {
                                                min: {
                                                    value: 0,
                                                    message: "The stock should be not less than 0",
                                                },
                                                max: {
                                                    value: 10000,
                                                    message: "The stock should be not more than 10000",
                                                },
                                                pattern: {
                                                    value: /^[0-9]*$/,
                                                    message: "Only numbers are allowed",
                                                }
                                            })}
                                            oninvalid={() => {
                                                trigger("stock_field");
                                            }}
                                            value={stock}
                                            onChange={(e) => setStock(e.target.value)}
                                            required
                                        />
                                        {errors.stock_field && (
                                            <small className="text-danger">{errors.stock_field.message}</small>
                                        )}
                                    </div>

                                    <div className="col-md-4 form-group">
                                        <label htmlFor="bulk_qty_filed">Minimum Bulk Quantity</label>
                                        <select
                                            id="bulk_qty_filed"
                                            className="form-control"
                                            placeholder="Select minimum order quantity..."
                                            value={bulk_qty}
                                            onChange={(e) => setBulk_qty(e.target.value)}
                                        >
                                            <option value="0" >Select minimum order quantity...</option>
                                            {Array.from({ length: 300 }, (_, index) => index + 1).map((item) => (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="col-md-4 form-group">
                                        <label htmlFor="seller_field">Seller Name</label>
                                        <input
                                            type="text"
                                            id="seller_field"
                                            className="form-control"
                                            placeholder="Enter seller name..."
                                            value={seller}
                                            onChange={(e) => setSeller(e.target.value)}
                                        />
                                    </div>
                                </div>

                                <div className='form-group'>
                                    <label>Images</label>
                                    <div className='custom-file'>
                                        <input
                                            type='file'
                                            name='file'
                                            className='custom-file-input'
                                            id='customFile'
                                            onChange={onChange}
                                            multiple
                                        />
                                        <label className='custom-file-label' htmlFor='customFile'>
                                            Choose Images
                                        </label>
                                    </div>

                                    {oldImages && oldImages.map(img => (
                                        <img key={img} src={`/${img.imageName}`} alt={img.imageName} className="mt-3 mr-2" width="55" height="52" />
                                    ))}

                                    {imagesPreview.map(img => (
                                        <img src={img} key={img} alt="Images Preview" className="mt-3 mr-2" width="55" height="52" />
                                    ))}

                                </div>

                                <div className="form-group">
                                    <div className="accordion" id="searchAccordion">
                                        <div className="accordion-group">
                                            <div className="accordion-heading text-center">
                                                <button type="button" className="accordion-toggle sp" data-toggle="collapse"
                                                    data-parent="#searchAccordion" href="#collapseTwo">
                                                    Update Product Specification
                                                </button>
                                            </div>
                                            <div id="collapseTwo" className="accordion-body collapse">
                                                <div className="accordion-inner">
                                                    <a href='/'>
                                                        <div className="row-fluid">
                                                            <FiledView1
                                                                productField={productField && productField} />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <div className="accordion" id="searchAccordion">
                                        <div className="accordion-group">
                                            <div className="accordion-heading text-center">
                                                <button type="button" className="accordion-toggle sp" data-toggle="collapse"
                                                    data-parent="#searchAccordion" href="#collapseTwo1">
                                                    Add Product Group
                                                </button>
                                            </div>

                                            <div id="collapseTwo1" className="accordion-body collapse">
                                                <div className="form-group">
                                                    <label htmlFor="code_field">Product Code <a>(<small>Please fill up the common value for simliar product</small>)</a></label>
                                                    <input
                                                        type="text"
                                                        id="code_field"
                                                        className="form-control upperCase1"
                                                        value={code}
                                                        onChange={(e) => setCode(e.target.value)}
                                                    />
                                                </div>
                                                <div className="accordion-inner">

                                                    <div>
                                                        <label htmlFor="headinf1">Heading 1</label>
                                                        <div className="form-row sizeProduct" >
                                                            <div className="col wid">
                                                                <input
                                                                    type="text"
                                                                    className="form-control required upperCase"
                                                                    placeholder="properties"
                                                                    name="properties"
                                                                    value={properties1}
                                                                    onChange={(e) => setProperties1(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col wid">
                                                                <input
                                                                    type="text"
                                                                    className="form-control required upperCase"
                                                                    placeholder="value"
                                                                    name="value"
                                                                    value={value1}
                                                                    onChange={(e) => setValue1(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label htmlFor="headinf1">Heading 2</label>
                                                        <div className="form-row sizeProduct" >
                                                            <div className="col wid">
                                                                <input
                                                                    type="text"
                                                                    className="form-control required upperCase"
                                                                    placeholder="properties"
                                                                    name="properties"
                                                                    value={properties2}
                                                                    onChange={(e) => setProperties2(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col wid">
                                                                <input
                                                                    type="text"
                                                                    className="form-control required upperCase"
                                                                    placeholder="value"
                                                                    name="value"
                                                                    value={value2}
                                                                    onChange={(e) => setValue2(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <label htmlFor="headinf1">Heading 3</label>
                                                        <div className="form-row sizeProduct" >
                                                            <div className="col wid">
                                                                <input
                                                                    type="text"
                                                                    className="form-control required upperCase"
                                                                    placeholder="properties"
                                                                    name="properties"
                                                                    value={properties3}
                                                                    onChange={(e) => setProperties3(e.target.value)}
                                                                />
                                                            </div>
                                                            <div className="col wid">
                                                                <input
                                                                    type="text"
                                                                    className="form-control required upperCase"
                                                                    placeholder="value"
                                                                    name="value"
                                                                    value={value3}
                                                                    onChange={(e) => setValue3(e.target.value)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <button
                                    id="login_button"
                                    type="submit"
                                    className="btn btn-block py-3"
                                    disabled={loading ? true : false}
                                >
                                    UPDATE PRODUCT
                                </button>
                            </form>
                        </div>
                    </Fragment>
                </div>
            </div>

        </Fragment>
    )
}

export default UpdateProduct
