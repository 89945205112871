import React, { Fragment } from "react";
import '../../foo.css';
import { Link } from "react-router-dom";
const footer = () => {

  function scrollTop() {
    const body = document.querySelector('#root');

    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)
  }

  return (
    <Fragment>
      <footer>
        <div className="footer-top">
          <div className="row">
            <div className="col-md-6 col-lg-3 about-footer">
              <br />
              <br />
              <br />
              <br />
              <h2 className="footer-title">
                <Link href="/">
                  <img src="/images/logo1.png" data-src="/images/logo1.png" loading="lazy" alt="Indicent Bulk Supply Pvt Ltd Logo" className="logo1-image" />
                </Link>
              </h2>

              {/* <a href="/enquiry"><button className="btn bg-danger text-white btn-lg ">Enquiry Now!</button></a> */}


            </div>
            <div className="col-md-6 col-lg-3 page-more-info">
              <div className="footer-title">
                <h4>Home</h4>
              </div>
              <ul>
                <li><a href="/category/Annular Cutter">Annular Cutter</a></li>
                <li><a href="/category/Hand Tools">Hand Tools</a></li>
                <li><a href="/category/Power Tools">Power Tools</a></li>
                <li><a href="/category/accessories">Accessories</a></li>
                <li><a href="/category/Drill and Taps">Drill and Taps</a></li>
              </ul>
            </div>

            <div className="col-md-6 col-lg-3 page-more-info">
              <div className="footer-title">
                <h4>Help</h4>
              </div>
              <ul>
                <li><a href="/term_of_use">Terms Of Use</a></li>
                <li><a href="/return_policy"> Return Policy</a></li>
                <li><a href="/copyright"> Copyright</a></li>
                <li><a href="/privacy"> Privacy Policy</a></li>
                <li><a href="/shipping-policy"> Shipping Policy</a></li>
              </ul>
            </div>
            <div className="col-md-6 col-lg-3 page-more-info about-footer">
              <div className="footer-title">
                <h4>Contact Us</h4>
              </div>
              <ul>
                <li><a href="tel:+919970399958">
                  <i className="fa fa-phone fa-flip-horizontal text-primary"></i>+91 9970399958</a></li>
                <li><a href="mailto:sales@indicent.co.in">
                  <i class="fa fa-envelope" aria-hidden="true"></i>sales@indicent.co.in</a></li>
                <li><a href="/enquiry"><button className="btn bg-danger text-white btn-lg ">Enquiry Now!</button></a></li>
              </ul>


              <ul class="footer-social  about-footer">
                <li><a href="https://www.facebook.com/profile.php?id=61555890136510" target="_blank"><i class="fa fa-facebook fa-2x  text-primary display-5"></i></a></li>
                <li><a href="https://api.whatsapp.com/send?phone=919970399958&lang=en&text=Hii...! Welcome to Indicent Bulk Supply Pvt Ltd." target="_blank"><i class="fa fa-whatsapp fa-2x  text-success display-5" aria-hidden="true"></i></a></li>
                <li><a href="https://www.instagram.com/indicent_bulk_supply/?igsh=YTZqNXR6ZHI2dDc4" target="_blank"><i class="fa fa-instagram fa-2x  text-danger  display-5"></i></a></li>
                <li><a href="" target="_blank"><i class="fa fa-linkedin fa-2x  text-primary  display-5"></i></a></li>
                <li><a href="" target="_blank"><i class="fa fa-xing-square fa-2x  text-white display-5"></i></a></li>
              </ul>

              {/* <li>
                    <i className="fa fa-map-marker"></i>
                    Row House No B/2,<br /> Creative Rainbow,
                    <br />Spine Road. Spine City Mall,
                    <br />Moshi, Pimpri Chinchwad,
                    <br /> Pune 412105
                  </li> */}

              {/* <div className="footer-logo">
                  <Link href="/"><img src="/images/logo.png" alt="Indicent Bulk Supply Pvt Ltd Logo" width="100" height="100" /></Link>
                </div> */}
            </div>
          </div>
        </div>
      </footer>


      <div className="footer1 bg-footer align-items-center">
        <div className="row">
          <div className="col-lg-6 col-md-12 text-lg-end mt-3 copyright">
            <div className="text-lg-right text-center">
              Copyrights &copy;{new Date().getFullYear()}{" "}
              <span className="indicent">Indicent Bulk Supply Pvt Ltd.</span> All Rights Reserved.
            </div>
          </div>
          <div className="col-lg-6 col-md-12 text-lg-end mt-3 copyright">
            <div className="text-lg-right text-center">
              Designed & Developed by{" "}
              <a href="https://weblinkservices.net/" target="_blank" rel="noreferrer">
                <img
                  src="/images/wlspl_logo.png"
                  alt="Weblink Services Pvt. Ltd."
                  loading="lazy"
                  width="130"
                  style={{ verticalAlign: "middle" }}
                />
              </a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <button className="scrollTop bg-light" title="Top" onClick={scrollTop}>
                <i className="fa fa-chevron-up text-danger" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
};

export default footer;
