import React, { Fragment, useEffect } from 'react'
import { Link } from 'react-router-dom'
import MetaData from '../layout/MetaData'

const OrderFail = () => {
    useEffect(() => {

        return () => {
            setTimeout(orderSuccess, 0)
        }

    }, [])

    function orderSuccess() {
        sessionStorage.removeItem('orderInfo')
    }

    return (
        <div className="container-fluid">
            <Fragment>
                <MetaData title={'Order Success'} />
                <div className="row justify-content-center">
                    <div className="col-6 mt-5 text-center">
                        <img className="my-5 img-fluid d-block mx-auto" src="/images/fail.png" alt="Order Fail" width="200" height="200" />

                        <h1 className='text-danger'>Payment Failed!</h1>
                        <h6 >The transaction failed. If your money was debited, you should get a refund.</h6><hr />

                        {/* <Link to="/confirm" className='menuorder'>Please Try Again...!</Link> */}
                    </div>
                </div>
            </Fragment>
        </div>
    )
}

export default OrderFail
