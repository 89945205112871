import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import Loader from '../layout/Loader'
import MetaData from '../layout/MetaData'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { login, register1, clearErrors } from '../../actions/userActions'
import { useForm } from "react-hook-form";
import { loadUser } from "../../actions/userActions";
// import { gapi } from 'gapi-script';
// import { GoogleLogin } from "react-google-login";
// import Logout from '../google/logout'
// const clientId = '860003330133-4lo989olsuvjun9hhf1krr6lcm3mb1mu.apps.googleusercontent.com';

const Login = () => {
    const navigate = useNavigate();
    const location = useLocation();
    // const params = useParams();

    // console.log("navigate", navigate);
    // console.log("location", location);
    // console.log("params", params);

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const alert = useAlert();
    const dispatch = useDispatch();
    const { register, handleSubmit, formState: { errors }, reset, trigger } = useForm();
    const { isAuthenticated, user, error, loading } = useSelector(state => state.auth);
    const redirect = location.search ? location.search.split('=')[1] : (location.state ? '/shipping' : '/')


    useEffect(() => {
        if (isAuthenticated) {
            dispatch(loadUser());
            navigate(redirect);
        }
        if (user) {
            if (user.isAdmin === 'admin') {
                dispatch(loadUser());
                navigate('/dashboard');
            }
        }

        if (error) {
            if (error === 'Login first to access this resource.' || error === 'jwt expired' || error === "jwt must be provided") {
            } else {
                alert.error(error);
                dispatch(clearErrors());
            }
        }
        // function start() {
        //     gapi.client.init({
        //         clientID: clientId,
        //         scope: ""
        //     })
        // };
        // gapi.load("client:auth2", start)

    }, [dispatch, navigate, alert, isAuthenticated, error]);

    const submitHandler = (data, e) => {
        e.preventDefault();
        dispatch(login(email, password));
        reset();
    }

    const onSuccess = (res) => {
        const formData = new FormData();
        formData.set('name', res.profileObj.givenName);
        formData.set('email', res.profileObj.email);
        formData.set('password', res.profileObj.googleId);
        formData.set('confirm_password', res.profileObj.googleId);
        dispatch(register1(formData))
        console.log("Login Sucess! Current User: ", res.profileObj);
    }

    const onFailure = (res) => {
        console.log("Login Failed!", res);
    }

    function click() {
        // toggle the type attribute
        const togglePassword = document.querySelector("#togglePassword");
        const passwordV = document.querySelector("#password_field");
        const type = passwordV.getAttribute("type") === "password" ? "text" : "password";
        togglePassword.className === 'fa fa-eye viewpass mr-4 text-muted' ? document.getElementById("togglePassword").className = 'fa fa-eye-slash viewpass mr-4 text-muted' : document.getElementById("togglePassword").className = 'fa fa-eye viewpass mr-4 text-muted';
        passwordV.setAttribute("type", type);
    }

    return (
        <Fragment>
            {loading ? <Loader /> : (
                <Fragment>
                    <MetaData title={'Login'} />
                    <div className="row wrapper" style={{ backgroundImage: "url('https://indicent.co.in/images/login.jpg')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '100%' }}>
                        <div className='col-md-3 col-lg-5'></div>
                        <div className="col-md-7 col-lg-5">
                            <form className="shadow-lg my-5 mb-3" onSubmit={handleSubmit(submitHandler)}>
                                <h1 className="mb-3">Login</h1>
                                <div className="form-group">
                                    <label htmlFor="email_field">Email</label>
                                    <input
                                        type="text"
                                        id="email_field"
                                        className={`form-control ${errors.email_field && "invalid"}`}
                                        {...register("email_field", {
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid Email ID",
                                            }
                                        })}
                                        onKeyUp={() => {
                                            trigger("email_field");
                                        }}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                    {errors.email_field && (
                                        <small className="text-danger">{errors.email_field.message}</small>
                                    )}
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password_field">Password</label>
                                    <input
                                        type="password"
                                        id="password_field"
                                        className={`form-control ${errors.message && "invalid"}`}
                                        {...register("password_field", {
                                            minLength: {
                                                value: 8,
                                                message: "Password should be at least 8 characters",
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: "Password should be not more than 50 characters",
                                            }
                                        })}
                                        onKeyUp={() => {
                                            trigger("password_field");
                                        }}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                    <span className="fa fa-eye viewpass text-muted mr-4" onClick={click} id="togglePassword"></span>
                                    {errors.password_field && (
                                        <small className="text-danger">{errors.password_field.message}</small>
                                    )}
                                </div>
                                <Link to="/password/forgot" className="float-right text-secondary mb-3">Forgot Password?</Link>
                                <button
                                    id="login_button"
                                    type="submit"
                                    className="btn btn-block py-3"
                                >
                                    LOGIN
                                </button><br />
                                <center className='mb-3'>
                                    <Link to="/register" className="text-secondary">Don't have an account? <strong>Register</strong> </Link>
                                    {/* <br />
                                    <br />
                                    <div id="signButton">
                                        <GoogleLogin
                                            clientId={clientId}
                                            buttonText="Login with Google"
                                            onSuccess={onSuccess}
                                            onFailure={onFailure}
                                            cookiePolicy={'single_host_origin'}
                                            isSigned={true}
                                        />
                                    </div>
                                    <Logout></Logout> */}
                                </center>
                                <br />
                            </form>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Login
