import React, { Fragment, useState, useEffect } from 'react'

import MetaData from '../layout/MetaData'
import { useForm } from "react-hook-form";
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPassword, clearErrors } from '../../actions/userActions'

const ForgotPassword = () => {
    const [email, setEmail] = useState()
    const alert = useAlert();
    const dispatch = useDispatch();

    const { error, loading, message } = useSelector(state => state.forgotPassword)
    const { register, handleSubmit, formState: { errors }, reset, trigger } = useForm();

    useEffect(() => {

        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if (message) {
            alert.success(message)
        }

    }, [dispatch, alert, error, message])

    const submitHandler = (data, e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.set('email', email);
        dispatch(forgotPassword(formData));
        reset();
    }

    return (
        <Fragment>
            <MetaData title={'Forgot Password'} />
            <div className="row wrapper" style={{ backgroundImage: "url('https://indicent.co.in/images/login.jpg')", backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', height: '100%' }}>
                <div className='col-md-3 col-lg-5'></div>
                <div className="col-md-7 col-lg-5">
                    <form className="shadow-lg mt-5 mb-3" onClick={handleSubmit(submitHandler)}>
                        <h1 className="mb-3">Forgot Password</h1>
                        <div className="form-group">
                            <label htmlFor="email_field">Enter Email</label>
                            <input
                                type="email"
                                id="email_field"
                                className={`form-control ${errors.email_field && "invalid"}`}
                                {...register("email_field", {
                                    required: "Please enter your Email ID",
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: "Invalid Email ID",
                                    }
                                })}
                                onKeyUp={() => {
                                    trigger("email_field");
                                }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                            {errors.email_field && (
                                <small className="text-danger">{errors.email_field.message}</small>
                            )}
                        </div>

                        <button
                            id="forgot_password_button"
                            type="submit"
                            className="btn btn-block py-3"
                            disabled={loading ? true : false}>
                            Send Email
                        </button>

                    </form>
                </div>
            </div>

        </Fragment>
    )
}

export default ForgotPassword
