import React from 'react'

const ShippinPolicy = () => {
    return (
        <div className='policy container-fluid text-justify p-5'>
            <h1 className='text-center'>SHIPPING POLICY </h1>
            <ol type='A'>
                <h2><li>What are the delivery charges? </li></h2>
                Delivery charge varies with each dealer.
                <p>Dealer incur relatively higher shipping costs on low value items. In such cases, charging a nominal delivery charge helps them offset logistics costs. Please check your order summary to understand the delivery charges for individual products. <br />
                    A Rs 50 charge for delivery per item may be appliedIf the order value is less than Rs 500. While, orders of Rs 500 or above are delivered free.
                </p>
                <br />
                <h2><li>Why does the delivery date not correspond to the delivery timeline of X-Y business days? </li></h2>
                <p>Dealer generally procure and ship the items within the time specified on the product page. Business days exclude public holidays and Sundays.<br />
                    Estimated delivery time depends on the following factors:
                </p>
                <ol>
                    <li>The Distributor offering the product</li>
                    <li>Product's availability with the Distributor</li>
                    <li>The destination to which you want the order shipped to and location of the Distributor.</li>
                </ol>
                <br />
                <h2><li>Are there any hidden costs (sales tax, octroi etc) on items sold by Dealer on Indicent Bulk Supply Private Limited (IBSPL)?</li></h2>
                There are NO hidden charges when you make a purchase on Indicent Bulk Supply Private Limited (IBSPL). List prices are final and all-inclusive. The price you see on the product page is exactly what you would pay.
                <p>Delivery charges are not hidden charges and are charged (if at all) extra depending on the Distributor's shipping policy.</p>
                <br />
                <h2><li>Why does the estimated delivery time vary for each Distributor?</li></h2>
                <p>You have probably noticed varying estimated delivery times for Dealer of the product you are interested in. Delivery times are influenced by product availability, geographic location of the Distributor, your shipping destination and the courier partner's time-to-deliver in your location.<br />
                    Please enter your default pin code on the product page (you don't have to enter it every single time) to know more accurate delivery times on the product page itself.
                </p>
                <br />
                <h2><li>Distributor does not/cannot ship to my area. Why? </li></h2>
                <p>Please enter your pincode on the product page (you don't have to enter it every single time) to know whether the product can be delivered to your location.<br />
                    If you haven't provided your pincode until the checkout stage, the pincode in your shipping address will be used to check for serviceability.
                </p>
                <ol>
                    <li>Whether the Distributor ships to your location</li>
                    <li>Legal restrictions, if any, in shipping particular products to your location</li>
                    <li>The availability of reliable courier partners in your location</li>
                </ol>
                <p>At times Dealer prefer not to ship to certain locations. This is entirely at their discretion.</p>
                <br />
                <h2><li>I did not receive my order but got a delivery confirmation SMS/Email. </li></h2>
                <p> In case the product was not delivered and you received a delivery confirmation email/SMS, report the issue within 7 days from the date of delivery confirmation for the Distributor to investigate.</p>

                <h2><li>What do the different tags like "In Stock", "Available" mean? </li></h2>

                <ol >
                    <h3><li>'In Stock' </li></h3>
                    <p>For items listed as "In Stock", Dealer will mention the delivery time based on your location pincode (usually 2-3 business days, 4-5 business days or 4-6 business days in areas where standard courier service is available). For other areas, orders will be sent by Registered Post through the Indian Postal Service which may take 1-2 weeks depending on the location. </p>
                    <h3><li>'Available' </li></h3>
                    <p>The Distributor might not have the item in stock but can procure it when an order is placed for the item. The delivery time will depend on the estimated procurement time and the estimated shipping time to your location.</p>
                    <h3><li>Out of Stock </li></h3>
                    <p>Currently, the item is not available for sale.</p>
                    <h3><li>Imported </li></h3>
                    <p>Sometimes, items have to be sourced by Dealer from outside India. These items are mentioned as 'Imported' on the product page and can take at least 10 days or more to be delivered to you.</p>
                    <h3><li>Back In Stock Soon </li></h3>
                    <p>The item is popular and is sold out. You can however 'book' an order for the product and it will be shipped according to the timelines mentioned by the Distributor.</p>

                    <h3><li>Temporarily Unavailable </li></h3>
                    <p> The product is currently out of stock and is not available for purchase. The product could to be in stock soon. Use the 'Notify Me' feature to know when it is available for purchase.</p>
                    <h3><li> Permanently Discontinued'</li></h3>
                    <p>This product is no longer available because it is obsolete and/or its production has been discontinued </p>
                    <h3><li>Out of Print </li></h3>
                    <p>This product is not available because it is no longer being published and has been permanently discontinued. </p>

                </ol>
                <h2><li> Does Indicent Bulk Supply Private Limited (IBSPL) deliver internationally?</li></h2>
                <p> As of now, Indicent Bulk Supply Private Limited (IBSPL) doesn't deliver items internationally.<br />
                    You will be able to make your purchases on our site from anywhere in the world with credit/debit cards issued in India and 21 other countries, but please ensure the delivery address is in India.
                </p>

            </ol>
        </div>
    )
}

export default ShippinPolicy