import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { useSelector } from 'react-redux'
import Loader from '../layout/Loader'
import MetaData from '../layout/MetaData'

const Profile = (MyProfile) => {

    const { user, loading } = useSelector(state => state.auth)

    // console.log("window.location.href", window.location.href);
    MyProfile = window.location.href;

    return (
        <Fragment>
            {loading ? <Loader /> : (
                <Fragment>
                    <MetaData title={'Your Profile'} />
                    <h2 className="mt-5 ml-5">My Profile</h2>
                    <div className="row justify-content-around mt-5 ml-1 mr-1 user-info">
                        <div className="col-12 col-md-3">
                            <figure className='avatar avatar-profile'>
                                <img className="rounded-circle img-fluid" src={user && user.imageName ? user.imageName : '/images/default_avatar.jpg'} alt={user.name} />
                            </figure>
                            <Link to="/me/update" id="edit_profile" className="btn btn-primary btn-block my-5">
                                Edit Profile
                            </Link>
                            <Link to={{
                                pathname: "/me/update/myaddress"
                            }} className="btn btn-warning btn-block my-5">
                                Manage Addresses
                            </Link>
                        </div>

                        <div className="col-12 col-md-5">
                            <h4>Full Name</h4>
                            <p>{user.name}</p>

                            <h4>Email Address</h4>
                            <p>{user.email}</p>

                            <h4>Joined on</h4>
                            <p>{user.date && new Date(String(user.date)).toLocaleDateString('en-IN')}</p>

                            <h4>Mobile Number</h4>
                            <p>{user.mobile}</p>

                            {user.role !== 'admin' && (
                                <Link to="/orders/me" className="btn btn-danger btn-block mt-5">
                                    My Orders
                                </Link>
                            )}

                            <Link to="/password/update" className="btn btn-primary btn-block mt-3">
                                Change Password
                            </Link>
                            <br />
                            <br />
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    )
}

export default Profile
