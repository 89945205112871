import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MetaData from '../layout/MetaData'
import { useDispatch, useSelector } from 'react-redux'
import { removeItemFromCart, removeBuyItem } from '../../actions/cartActions'
import { myOrders } from '../../actions/orderActions'
import Loader from "../layout/Loader";


const OrderSuccess = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    var { cartItems } = useSelector(state => state.cart);
    var { buyItem, shippingInfo } = useSelector(state => state.buy);
    const { orders } = useSelector(state => state.myOrders);

    const shop = buyItem.length ? buyItem : cartItems;

    console.log("buyItem", buyItem);

    const order = {
        orderItems: shop,
        shippingInfo
    }

    const orderInfo = JSON.parse(sessionStorage.getItem('orderInfo'));
    console.log("orderInfo", orderInfo);
    // console.log("order", order);

    // if (orderInfo) {
    //     order.itemsPrice = orderInfo.itemsPrice
    //     order.shippingPrice = orderInfo.shippingPrice
    //     order.totalPrice = orderInfo.totalPrice
    //     order.order_id = orderInfo.order_id
    // }

    const [orderId, setOrderId] = useState(orderInfo);
    // const [orderAmount, setOrderAmount] = useState(orderInfo.totalPrice);
    var id;
    var paymentStatus;
    var paymentAmount;

    if (orders) {
        orders.map((item) => {
            if (item.order_id === orderId) {
                id = item.order_id
                paymentStatus = item.paymentStatus
                paymentAmount = item.totalPrice
                console.log("item", item.order_id);
            }
        })
    }

    useEffect(() => {
        dispatch(myOrders());

        // if (performance.navigation.redirectCount === 0 && performance.navigation.type === 1) {
        //     navigate('/');
        // }

        return () => {
            setTimeout(orderSuccess, 0)
        }

    }, [])

    function orderSuccess() {
        //check if buy item not add
        if (buyItem !== null) {
            buyItem.map(item => (
                dispatch(removeBuyItem(Buffer.from((item.product).toString(), 'binary').toString('base64')))))
        } else {
            cartItems.map(item => (
                dispatch(removeItemFromCart(Buffer.from((item.product).toString(), 'binary').toString('base64')))))
        }
        sessionStorage.removeItem('orderInfo')
    }

    return (
        <div className="container-fluid">
            <Fragment>
                {orderId && (id === orderId) && (paymentStatus === 'Success') ? (
                    <Fragment>
                        <MetaData title={'Order Success'} />
                        <div className="row justify-content-center">
                            <div className="col-6 mt-5 text-center">
                                {orderId && (id === orderId) && (paymentStatus === 'Success') ? (<>
                                    <img className="my-5 img-fluid d-block mx-auto" src="/images/order_success.png" alt="Order Success" width="200" height="200" />
                                    <h3>ORDER ID : {orderId ? orderId : '#'} </h3>
                                    <h3> Total Amount : ₹{paymentAmount ? paymentAmount : '#'} </h3>
                                    <h2>Your Order has been placed successfully.</h2><br />
                                    <div className='row d-inline-flex p-2'>
                                        <h5 className="ml-2 px-md-3">
                                            <Link to="/orders/me" className='menuorder order-btn'>Go to Orders</Link>
                                        </h5>
                                        <h5 className="ml-2 px-md-3">
                                            <Link to="/" className='menuorder order-btn'>Go to Industry Central</Link>
                                        </h5>
                                        <br />
                                        <br />
                                    </div>
                                </>
                                ) : (<>
                                    <h2>Continue shopping on Industry central.</h2><br />
                                    <div className='row d-inline-flex p-2'>
                                        <h5 className="ml-2 px-md-3">
                                            <Link to="/orders/me" className='menuorder order-btn'>Your Orders</Link>
                                        </h5>
                                        <h5 className="ml-2 px-md-3">
                                            <Link to="/" className='menuorder order-btn'>Home</Link>
                                        </h5>
                                        <br />
                                        <br />
                                    </div>
                                </>
                                )}

                            </div>
                        </div>

                    </Fragment >

                ) : (
                    <Loader />
                )
                }
            </Fragment >
        </div >
    )
}

export default OrderSuccess
