import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from '../layout/Loader'
import { loadUser } from "../../actions/userActions";

const ProtectedRoute = ({ children, isAdmin }) => {
    const location = useLocation();
    const navigate = useNavigate();

    const {
        isAuthenticated = false,
        loading = true,
        user,
    } = useSelector((state) => state.auth);

    const dispatch = useDispatch();
    // console.log("location.state", window.location.href);

    useEffect(() => {
        if (!user) {
            dispatch(loadUser());
        }
    }, [isAuthenticated, loading, dispatch, user]);

    if (loading) return <h1> <Loader /> </h1>;

    if (!loading && isAuthenticated) {
        if (isAdmin === true && user.role !== "admin") {
            return <Navigate to="/" />;
        }
        return children;
    } else {
        // return <Navigate to={{ pathname: '/login', state: window.location.href }} />;
        return navigate('/login', { state: location.state });
    }

};

export default ProtectedRoute;