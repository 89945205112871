import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import MetaData from '../layout/MetaData'
import CheckoutSteps from './CheckoutSteps'
import { getAllUserCoupons } from '../../actions/orderActions'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { myOrders, ccavReqHandler, getOrderId } from '../../actions/orderActions'
import { updateStock } from '../../actions/productActions'
import ReadMoreCoupon from '../product/ReadMoreCoupon'
import { addItemToCart } from '../../actions/cartActions'
import { createOrder } from '../../actions/orderActions'
const querystring = require('querystring')
// import pincode from "pincode-distance"

const ConfirmOrder = () => {
    const alert = useAlert();
    const dispatch = useDispatch();
    // const Pincode = new pincode();

    const [applyCoupon, setApplyCoupon] = useState();
    const [coupon_code, setCoupon_code] = useState('');
    const [order_code, setOrder_code] = useState(1);
    const [redeem, setRedeem] = useState(0);
    const [description, setDescription] = useState();

    const [merchant_id, setMerchant_id] = useState('3100108');
    const [order_id, setOrder_id] = useState(getOrderId());
    const [currency, setCurrency] = useState('INR');
    const [amount, setAmount] = useState(0);
    const [redirect_url, setRedirect_url] = useState('https://indicent.co.in/api/v1/ccavResponseHandler');
    const [cancel_url, setCancel_url] = useState('https://indicent.co.in/api/v1/payment');
    const [language, setLanguage] = useState('EN');

    const { cartItems } = useSelector(state => state.cart)
    const { buyItem, shippingInfo } = useSelector(state => state.buy)
    // const { user } = useSelector(state => state.auth)
    const { orderStockResult } = useSelector(state => state.newOrder)
    const { coupons } = useSelector(state => state.allUserCoupons);
    const { error, orders } = useSelector(state => state.myOrders);
    const { encRequest, accessCode, amountRes, order_id_res } = useSelector(state => state.enc_request);

    const shop = buyItem.length ? buyItem : cartItems;

    // if (distance <= 100) {
    //     shippingPriceDistance = 0
    // }
    // if (distance === -1) {
    //     shippingPriceDistance = 2000;
    //     distance = 1900
    // }

    // if (distance > 100) {
    //     shippingPriceDistance = 200
    // }

    // if (distance >= 500) {
    //     shippingPriceDistance = 500
    // }

    // if (distance >= 1000) {
    //     shippingPriceDistance = 1000
    // }

    // if (distance >= 2000) {
    //     shippingPriceDistance = 3000
    // }

    // if (distance >= 3500) {
    //     shippingPriceDistance = 3500
    // }

    // console.log("distance", distance);
    // console.log("orderStockResult", orderStockResult);


    useEffect(() => {
        dispatch(getAllUserCoupons());
        dispatch(myOrders());
        updateStockCheck()

        if (orders) {
            orders.forEach((or) => {
                if (or.coupon_code === applyCoupon) {
                    setOrder_code(0);
                }
            })
        }
        if (cartItems !== null) {
            cartItems.forEach((item) => {
                dispatch(addItemToCart(Buffer.from((item.product).toString(), 'binary').toString('base64'), item.quantity))
            });
        }

        processToPayment();
        orderHandller()

        return () => {
            updateStockCheck1()
        }

    }, [dispatch, alert, redeem, error, applyCoupon, coupon_code])

    // Calculate Order Prices
    const itemsPrice = buyItem.length ? buyItem.reduce((acc, item) => acc + Number((item.sale_price)).toFixed(2) * item.quantity, 0)
        : cartItems.reduce((acc, item) =>
            (acc + (item.stock === 0 || item.stock < item.quantity ? 0 : Number(item.quantity * item.sale_price))), 0)
    // const shippingPrice = itemsPrice === 0 ? 0 : (itemsPrice > 50000 ? distance * 10 : shippingPriceDistance);
    const shippingPrice = itemsPrice === 0 ? 0 : (itemsPrice > 500 ? 0 : 0);
    const tax_amount = buyItem.length ? buyItem.reduce((acc, item) => acc + Number((item.tax_amount)).toFixed(2) * item.quantity, 0)
        : cartItems.reduce((acc, item) =>
            (acc + (item.stock === 0 || item.stock < item.quantity ? 0 : Number(item.quantity * item.tax_amount))), 0)

    const totalPrice = Number((itemsPrice + shippingPrice - redeem)).toFixed(2)

    const orderHandller = () => {
        const orderInfo = {
            itemsPrice: Number(itemsPrice).toFixed(2),
            shippingPrice,
            totalPrice,
            redeem,
            coupon_code,
            order_id
        };

        const order1 = {
            orderItems: JSON.parse(JSON.stringify(shop)),
            shippingInfo,
            orderInfo,
            shop_length: shop.length
        }

        dispatch(createOrder(order1));

    }

    const processToPayment = () => {
        //send encrypt data to payment
        const formData = new FormData();
        formData.set("merchant_id", merchant_id);
        formData.set("order_id", order_id);
        formData.set("currency", currency);
        formData.set("redirect_url", redirect_url);
        formData.set("cancel_url", cancel_url);
        formData.set("amount", totalPrice);
        formData.set("language", language);

        var object = {};
        formData.forEach((value, key) => object[key] = value);
        var json = object
        if (merchant_id && order_id && currency && redirect_url && cancel_url && parseInt(totalPrice) && language) {
            dispatch(ccavReqHandler(querystring.stringify(json)));
            // navigate('/payment');
        }
    }

    const orderSuccess = () => {
        sessionStorage.setItem('orderInfo', JSON.stringify(order_id));
    }

    const updateStockCheck = () => {
        const formData = new FormData();
        formData.set("order_id", order_id);
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        var json = object
        dispatch(updateStock(json));
    }

    const updateStockCheck1 = () => {
        const formData = new FormData();
        formData.set("order_id", 111);
        var object = {};
        formData.forEach((value, key) => object[key] = value);
        var json = object
        dispatch(updateStock(json));
    }

    const getApplyCoupon = () => {
        if (applyCoupon) {
            if (order_code) {
                var cc = 1
                coupons && coupons.forEach((coupon) => {
                    if (coupon.coupon_code === applyCoupon) {
                        cc = 0;
                        if (coupon.coupon_code === applyCoupon) {
                            if (coupon.minValue <= itemsPrice) {
                                const x = new Date().toISOString().split('T')[0];
                                const y = coupon.lastDate.split('T')[0];
                                if (x <= y) {
                                    // console.log("cashback", coupon.cashback);
                                    // console.log("minValue", coupon.minValue);
                                    // console.log("description", coupon.description);
                                    setDescription(coupon.description);
                                    setRedeem(coupon.cashback);
                                    setCoupon_code(coupon.coupon_code);
                                    updateStockCheck1()
                                    processToPayment();
                                    orderHandller();
                                    alert.info(<div style={{ textTransform: 'initial' }}>Voila..! {coupon.coupon_code} has been applied successfully.
                                        <br />Proceed to pay now to get cashback RS. {coupon.cashback}</div>);

                                    < small className="text-danger" > {coupon.description}</ small>;

                                } else {
                                    alert.error("Promo code has expired.");
                                }
                            } else {
                                alert.error(`This promo code required minimum price RS ${coupon.minValue}`);
                            }
                        }
                    }
                })
                if (cc) {
                    alert.error("Promo code is invalid.");
                }

            } else {
                setOrder_code(1);
                alert.error("You already applied this promotion");
            }
        } else {
            alert.error("Promo code is empty");
        }
    }

    return (
        <div className="container-fluid">
            <Fragment>
                <MetaData title={'Confirm Order'} />
                <CheckoutSteps shipping confirmOrder />
                <div className="row d-flex justify-content-between">
                    <div className="container-xl col-12 col-lg-8 mt-5 order-confirm">
                        <h4 className="mb-3">Shipping Info</h4>
                        <p><b>Name:</b> {shippingInfo && shippingInfo.name}</p>
                        <p><b>Phone:</b> {shippingInfo.mobile}</p>
                        <p className="mb-4"><b>Address:</b> {`${shippingInfo.flat ? shippingInfo.flat + "," : ""} ${shippingInfo.area ? shippingInfo.area + "," : ""}
                        ${shippingInfo.landmark ? shippingInfo.landmark + "," : ""} ${shippingInfo.city ? shippingInfo.city + "," : ""}
                         ${shippingInfo.state ? shippingInfo.state + "," : ""} ${shippingInfo.country ? shippingInfo.country + "," : ""} ${shippingInfo.postalCode ? shippingInfo.postalCode : ""}`}</p>
                        {shippingInfo.gstn ? <p><b>GSTN No:</b> {shippingInfo.gstn} </p> : ""}
                        <hr />
                        <h4 className="mt-4">Your Buying Items:</h4>

                        {buyItem.length ? (
                            buyItem.map(item => (
                                <Fragment>
                                    <hr />
                                    <div className="cart-item my-1" key={item.product}>
                                        <div className="row">
                                            <div className="col-4 col-lg-2">
                                                <img src={item.image} alt="Laptop" height="45" width="65" />
                                            </div>

                                            <div className="col-5 col-lg-6">
                                                <Link to={`/product/${Buffer.from((item.product).toString(), 'binary').toString('base64')}?${encodeURIComponent(item.name)}`}>{item.name}</Link>
                                            </div>

                                            <div className="col-4 col-lg-4 mt-4 mt-lg-0">
                                                <p>{item.quantity} x RS {Number((item.sale_price)).toLocaleString('en-IN', { maximumFractionDigits: 2 })} = <b>RS {Number((item.quantity * item.sale_price)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </Fragment>
                            ))
                        ) : (
                            cartItems.map(item => (
                                (item.stock === 0 || item.stock < item.quantity) ? ("") : (<Fragment>
                                    <hr />
                                    <div className="cart-item my-1" key={item.product}>
                                        <div className="row">
                                            <div className="col-4 col-lg-2">
                                                <img src={item.image} alt="Laptop" height="45" width="65" />
                                            </div>

                                            <div className="col-5 col-lg-6">
                                                <Link to={`/product/${Buffer.from((item.product).toString(), 'binary').toString('base64')}?${encodeURIComponent(item.name)}`}>{item.name}</Link>
                                            </div>

                                            <div className="col-4 col-lg-4 mt-4 mt-lg-0">
                                                <p>{item.quantity} x RS {Number((item.sale_price)).toLocaleString('en-IN', { maximumFractionDigits: 2 })} = <b>RS {Number((item.quantity * item.sale_price)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</b></p>
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </Fragment>)
                            ))
                        )}

                    </div>
                    <div className="col-12 col-lg-3 my-1">
                        <div id="order_summary">
                            <h4>Order Summary</h4>
                            <hr />
                            <div className="row">
                                <div className="col-md-9 form-group">
                                    <input
                                        type="text"
                                        id="name_field"
                                        placeholder='Have a promo code?'
                                        className={`form-control`}
                                        value={applyCoupon}
                                        onChange={(e) => setApplyCoupon(e.target.value)}
                                    />
                                    {description ? (<ReadMoreCoupon text={description} />) : ""}
                                </div>
                                <div className="col-md-3 form-group">
                                    <input className='btn my-0 float-right review-btn px-4 text-white' type='button' onClick={getApplyCoupon} value="Apply" />
                                </div>
                            </div>
                            <hr />
                            <p>Subtotal:  <span className="order-summary-values">&#8377; {(itemsPrice - tax_amount).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                            <p>Total GST:  <span className="order-summary-values">&#8377; {tax_amount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                            {shippingPrice ? <p>Shipping cost: <span pan className="order-summary-values">&#8377; {shippingPrice.toFixed(2)}</span></p> : ""}
                            {/* <p>Estimated tax:  <span className="order-summary-values">&#8377; {taxPrice}</span></p> */}
                            {redeem ? <p>Coupon Amount:  <span className="order-summary-values">&#8209; &nbsp; &#8377; {redeem}</span></p> : ""}
                            <hr />
                            <p>Total: <span className="order-summary-values">&#8377; {Number(totalPrice).toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                            <hr />

                            {(parseInt(totalPrice) == parseInt(amountRes) && shippingInfo.mobile && orderStockResult && parseInt(order_id_res) == parseInt(order_id)) ? (
                                <form id="nonseamless" method="post" name="redirect" action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction" >
                                    <input type="hidden" id="encRequest" name="encRequest" value={`${encRequest}`} />
                                    <input type="hidden" name="access_code" id="access_code" value={`${accessCode}`} />
                                    <button type='submit' onClick={orderSuccess} id="checkout_btn" className="btn btn-primary btn-block" disabled={(parseInt(totalPrice) == parseInt(amountRes) && shippingInfo.mobile && orderStockResult && parseInt(order_id_res) == parseInt(order_id) ? 0 : 1)} >{orderStockResult === false ? "OUT OF STOCK" : 'Proceed to Payment'}</button>
                                    {/* <button type='submit' onClick={orderSuccess} id="checkout_btn" className="btn btn-primary btn-block" disabled={((parseInt(totalPrice)) == amountRes && shippingInfo.mobile && orderStockResult && order_id_res == order_id) ? 0 : 1)} >{orderStockResult === false ? "OUT OF STOCK" : 'Proceed to Payment'}</button> */}
                                </form>
                            ) : (
                                <div class="alert alert-danger" role="alert">
                                    <h4 class="alert-heading">Payment</h4>
                                    <p>Sorry, Could not initiate transaction please try again.</p>
                                    <hr />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Fragment >
        </div >
    )
}

export default ConfirmOrder
