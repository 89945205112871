import React from 'react'

const loader = () => {
    return (
        <div className='loader'>
            {/* <img src="/images/logo.png"  width="50" height="50" /> */}
            <div className="lds-spinner">
                <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
            </div>
        </div>
    )
}

export default loader
