export const CREATE_ORDER_REQUEST = 'CREATE_ORDER_REQUEST'
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS'
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL'

export const MY_ORDERS_REQUEST = 'MY_ORDERS_REQUEST'
export const MY_ORDERS_SUCCESS = 'MY_ORDERS_SUCCESS'
export const MY_ORDERS_FAIL = 'MY_ORDERS_FAIL'

export const ALL_ORDERS_REQUEST = 'ALL_ORDERS_REQUEST'
export const ALL_ORDERS_SUCCESS = 'ALL_ORDERS_SUCCESS'
export const ALL_ORDERS_FAIL = 'ALL_ORDERS_FAIL'

export const UPDATE_ORDER_REQUEST = 'UPDATE_ORDER_REQUEST'
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS'
export const UPDATE_ORDER_RESET = 'UPDATE_ORDER_RESET'
export const UPDATE_ORDER_FAIL = 'UPDATE_ORDER_FAIL'

export const DELETE_ORDER_REQUEST = 'DELETE_ORDER_REQUEST'
export const DELETE_ORDER_SUCCESS = 'DELETE_ORDER_SUCCESS'
export const DELETE_ORDER_RESET = 'DELETE_ORDER_RESET'
export const DELETE_ORDER_FAIL = 'DELETE_ORDER_FAIL'

export const CANCEL_ORDER_REQUEST = 'CANCEL_ORDER_REQUEST'
export const CANCEL_ORDER_SUCCESS = 'CANCEL_ORDER_SUCCESS'
export const CANCEL_ORDER_RESET = 'CANCEL_ORDER_RESET'
export const CANCEL_ORDER_FAIL = 'CANCEL_ORDER_FAIL'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'


export const ALL_COUPON_REQUEST = 'ALL_COUPON_REQUEST'
export const ALL_COUPON_SUCCESS = 'ALL_COUPON_SUCCESS'
export const ALL_COUPON_FAIL = 'ALL_COUPON_FAIL'

export const CREATE_COUPON_REQUEST = 'CREATE_COUPON_REQUEST'
export const CREATE_COUPON_SUCCESS = 'CREATE_COUPON_SUCCESS'
export const CREATE_COUPON_RESET = 'CREATE_COUPON_RESET'
export const CREATE_COUPON_FAIL = 'CREATE_COUPON_FAIL'

export const DELETE_COUPON_REQUEST = 'DELETE_COUPON_REQUEST'
export const DELETE_COUPON_SUCCESS = 'DELETE_COUPON_SUCCESS'
export const DELETE_COUPON_FAIL = 'DELETE_ORDER_FAIL'
export const DELETE_COUPON_RESET = 'DELETE_COUPON_RESET'

export const UPDATE_COUPON_REQUEST = 'UPDATE_COUPON_REQUEST'
export const UPDATE_COUPON_SUCCESS = 'UPDATE_COUPON_SUCCESS'
export const UPDATE_COUPON_RESET = 'UPDATE_COUPON_RESET'
export const UPDATE_COUPON_FAIL = 'UPDATE_COUPON_FAIL'

export const COUPON_DETAILS_REQUEST = 'COUPON_DETAILS_REQUEST'
export const COUPON_DETAILS_SUCCESS = 'COUPON_DETAILS_SUCCESS'
export const COUPON_DETAILS_FAIL = 'COUPON_DETAILS_FAIL'

export const PAYMENT_REQUEST = 'PAYMENT_REQUEST'
export const PAYMENT_REQUEST_SUCCESS = 'PAYMENT_REQUEST_SUCCESS'
export const PAYMENT_REQUEST_FAIL = 'PAYMENT_REQUEST_FAIL'

export const PAYMENT_COMPLETE_REQUEST = 'PAYMENT_COMPLETE_REQUEST'
export const PAYMENT_COMPLETE_SUCCESS = 'PAYMENT_REQUEST_SUCCESS'
export const PAYMENT_COMPLETE_FAIL = 'PAYMENT_COMPLETE_FAIL'

export const RETURN_ORDER_REQUEST = 'RETURN_ORDER_REQUEST'
export const RETURN_ORDER_SUCCESS = 'PAYMENT_REQUEST_SUCCESS'
export const RETURN_ORDER_FAIL = 'RETURN_ORDER_FAIL'
export const RETURN_ORDER_RESET = 'RETURN_ORDER_RESET'

export const CANCEL_RETURN_ORDER_REQUEST = 'CANCEL_RETURN_ORDER_REQUEST'
export const CANCEL_RETURN_ORDER_SUCCESS = 'CANCEL_RETURN_ORDER_SUCCESS'
export const CANCEL_RETURN_ORDER_FAIL = 'CANCEL_RETURN_ORDER_FAIL'
export const CANCEL_RETURN_ORDER_RESET = 'CANCEL_RETURN_ORDER_RESET'

export const REPORT_REQUEST = 'REPORT_REQUEST'
export const REPORT_SUCCESS = 'REPORT_SUCCESS'
export const REPORT_FAIL = 'REPORT_FAIL'
export const REPORT_RESET = 'REPORT_RESET'

export const SALES_REQUEST = 'SALES_REQUEST'
export const SALES_SUCCESS = 'SALES_SUCCESS'
export const SALES_FAIL = 'SALES_FAIL'


export const CLEAR_ERRORS = 'CLEAR_ERRORS'
