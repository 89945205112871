import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'

import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader'
import Sidebar from './Sidebar'
import Dialog from "../../Dialog";
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { allUsers, deleteUser, clearErrors } from '../../actions/userActions'
import { DELETE_USER_RESET } from '../../constants/userConstants'
import { CSVLink } from 'react-csv';

const UsersList = () => {
    const navigate = useNavigate();
    const alert = useAlert();
    const dispatch = useDispatch();

    const { loading, error, users } = useSelector(state => state.allUsers);
    const { isDeleted } = useSelector(state => state.user)
    // console.log("users",users);

    useEffect(() => {
        dispatch(allUsers());

        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }

        if (isDeleted) {
            alert.success('User deleted successfully');
            navigate('/admin/users', { replace: true });
            dispatch({ type: DELETE_USER_RESET })
        }

    }, [dispatch, navigate, alert, error, isDeleted])

    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        //Update
        nameProduct: ""
    });

    const handleDialog = (message, isLoading, nameProduct, id) => {
        setDialog({
            message,
            isLoading,
            //Update
            nameProduct,
            id
        });
    };

    const deleteUserHandler = (id, name) => {
        handleDialog("Are you sure you want to delete user?", true, name, id);
    }

    const areUSureDelete = (choose) => {
        if (choose) {
            dispatch(deleteUser(dialog.id))
            handleDialog("", false);
        } else {
            handleDialog("", false);
        }
    }
    const csvData = JSON.parse(JSON.stringify(users ? users : []));
    const [list, setList] = useState({ display: "block" });
    const hide = () => {
        if (list.display === "block") {
            setList({ display: "none" })
        } else {
            setList({ display: "block" })
        }
    }

    const setUsers = () => {
        const data = {
            columns: [
                {
                    label: 'User ID',
                    field: 'id',
                    sort: 'asc',
                    width: 220
                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 220
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                    width: 220
                },
                {
                    label: 'Role',
                    field: 'role',
                    sort: 'asc',
                    width: 220
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    width: 220
                },
            ],
            rows: []
        }

        users.forEach(user => {
            const EID = Buffer.from(`${user.id}`, 'binary').toString('base64')
            data.rows.push({
                id: EID,
                name: user.name,
                email: user.email,
                role: user.role,

                actions: <Fragment>
                    <button onClick={() => { window.location.href = `/admin/user/${EID}` }} className="btn btn-primary py-1 px-2">
                        <i className="fa fa-pencil"></i>
                    </button>
                    <button className="btn btn-danger py-1 px-2 ml-2" onClick={() => deleteUserHandler(EID, user.name)} >
                        <i className="fa fa-trash"></i>
                    </button>
                </Fragment>
            })
        })
        return data;
    }

    return (
        <Fragment>
            <MetaData title={'All Users'} />
            <div className="row">
                <div style={list} className="col-12 col-md-2">
                    <Sidebar />
                </div>

                <div className={`${list.display === 'block' ? "col-12 col-md-10" : "col-12 col-md-12"}`}>
                    <Fragment>
                        <div className='my-4'></div>
                        <button className="mt-3 proda menuorder" onClick={hide}><i className="fa fa-bars" aria-hidden="true"></i></button>
                        <h1 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-2" >All Users</h1>
                        <h5 style={{ display: 'inline' }} className="marginleft mr-2 mt-5 px-md-2"> <CSVLink className='menuorder' data={csvData}
                            filename={"users.csv"} target="_blank" >Download</CSVLink></h5>
                        <br />
                        <br />
                        {loading ? <Loader /> : (
                            <MDBDataTable
                                data={setUsers()}
                                className="px-3"
                                bordered
                                striped
                                hover scrollX
                            />
                        )}

                    </Fragment>
                </div>
                {dialog.isLoading && (
                    <Dialog
                        //Update
                        nameProduct={dialog.nameProduct}
                        onDialog={areUSureDelete}
                        message={dialog.message}
                        id={dialog.id}
                    />
                )}
            </div>
        </Fragment>
    )
}

export default UsersList
