import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import MetaData from "../layout/MetaData";
import Sidebar from "./Sidebar";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "../../Dialog";
import { updateBanner, updateBannerSideImage, clearErrors, getSideImage, getSiderImage, deleteBannerImage, deleteSideImage } from "../../actions/productActions";

const BannerImages = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const alert = useAlert();
    const { success, error } = useSelector((state) => state.sideImage);
    const { sideImages } = useSelector((state) => state.allSideImage);
    const { siderImages } = useSelector((state) => state.allSiderImage);


    const [keywordSlider1, setKeywordSlider1] = useState();
    const [fileSider1, setFileSider1] = useState();
    const [avatarPreview1, setAvatarPreview1] = useState('/images/default_banner.jpg')

    const [keywordSlider2, setKeywordSlider2] = useState();
    const [fileSider2, setFileSider2] = useState();
    const [avatarPreview2, setAvatarPreview2] = useState('/images/default_banner.jpg')

    const [keywordSlider3, setKeywordSlider3] = useState();
    const [fileSider3, setFileSider3] = useState();
    const [avatarPreview3, setAvatarPreview3] = useState('/images/default_banner.jpg')

    const [keywordSlider4, setKeywordSlider4] = useState();
    const [fileSider4, setFileSider4] = useState();
    const [avatarPreview4, setAvatarPreview4] = useState('/images/default_banner.jpg')

    const [keywordSlider5, setKeywordSlider5] = useState();
    const [fileSider5, setFileSider5] = useState();
    const [avatarPreview5, setAvatarPreview5] = useState('/images/default_banner.jpg')

    const [keywordSlider6, setKeywordSlider6] = useState();
    const [fileSider6, setFileSider6] = useState();
    const [avatarPreview6, setAvatarPreview6] = useState('/images/default_banner.jpg')

    const [keywordSlider7, setKeywordSlider7] = useState();
    const [fileSider7, setFileSider7] = useState();
    const [avatarPreview7, setAvatarPreview7] = useState('/images/default_banner.jpg')


    const [keyword1, setKeyword1] = useState();
    const [fileSideImage1, setFileSideImage1] = useState();
    const [avatarPreviewSideImage1, setAvatarPreviewSideImage1] = useState('/images/default_banner.jpg')

    const [keyword2, setKeyword2] = useState();
    const [fileSideImage2, setFileSideImage2] = useState();
    const [avatarPreviewSideImage2, setAvatarPreviewSideImage2] = useState('/images/default_banner.jpg')

    const [keyword3, setKeyword3] = useState();
    const [fileSideImage3, setFileSideImage3] = useState();
    const [avatarPreviewSideImage3, setAvatarPreviewSideImage3] = useState('/images/default_banner.jpg')


    useEffect(() => {
        dispatch(getSideImage())
        dispatch(getSiderImage())

        if (siderImages) {
            if (siderImages[0]) {
                if (siderImages[0].id) {
                    setKeywordSlider1(siderImages[0].keyword)
                    // setFileSideImage1(sideImages[0].url)
                    setAvatarPreview1(siderImages[0].url ? siderImages[0].url : "/images/default_banner.jpg")
                }
            }
            if (siderImages[1]) {
                if (siderImages[1].id) {
                    setKeywordSlider2(siderImages[1].keyword)
                    // setFileSideImage2(sideImages[1].url)
                    setAvatarPreview2(siderImages[1].url ? siderImages[1].url : "/images/default_banner.jpg")
                }
            }
            if (siderImages[2]) {
                if (siderImages[2].id) {
                    setKeywordSlider3(siderImages[2].keyword)
                    // setFileSideImage3(sideImages[2].url)
                    setAvatarPreview3(siderImages[2].url ? siderImages[2].url : "/images/default_banner.jpg")
                }
            }
            if (siderImages[3]) {
                if (siderImages[3].id) {
                    setKeywordSlider4(siderImages[3].keyword)
                    // setFileSideImage3(sideImages[2].url)
                    setAvatarPreview4(siderImages[3].url ? siderImages[3].url : "/images/default_banner.jpg")
                }
            }
            if (siderImages[4]) {
                if (siderImages[4].id) {
                    setKeywordSlider5(siderImages[4].keyword)
                    // setFileSideImage3(sideImages[2].url)
                    setAvatarPreview5(siderImages[4].url ? siderImages[4].url : "/images/default_banner.jpg")
                }
            }

            if (siderImages[5]) {
                if (siderImages[5].id) {
                    setKeywordSlider6(siderImages[5].keyword)
                    // setFileSideImage3(sideImages[2].url)
                    setAvatarPreview6(siderImages[5].url ? siderImages[5].url : "/images/default_banner.jpg")
                }
            }

            if (siderImages[6]) {
                if (siderImages[6].id) {
                    setKeywordSlider7(siderImages[6].keyword)
                    // setFileSideImage3(sideImages[2].url)
                    setAvatarPreview7(siderImages[6].url ? siderImages[6].url : "/images/default_banner.jpg")
                }
            }
        }

        if (sideImages) {
            if (sideImages[0]) {
                if (sideImages[0].id) {
                    setKeyword1(sideImages[0].keyword)
                    // setFileSider1(sideImages[0].url)
                    setAvatarPreviewSideImage1(sideImages[0].url ? sideImages[0].url : "/images/default_banner.jpg")
                }
            }
            if (sideImages[1]) {
                if (sideImages[1].id) {
                    setKeyword2(sideImages[1].keyword)
                    // setFileSideImage2(sideImages[1].url)
                    setAvatarPreviewSideImage2(sideImages[1].url ? sideImages[1].url : "/images/default_banner.jpg")
                }
            }
            if (sideImages[2]) {
                if (sideImages[2].id) {
                    setKeyword3(sideImages[2].keyword)
                    // setFileSideImage3(sideImages[2].url)
                    setAvatarPreviewSideImage3(sideImages[2].url ? sideImages[2].url : "/images/default_banner.jpg")
                }
            }
        }

        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        if (success) {
            window.location.reload();
            // navigate('/admin/banner')
            alert.success("Images Modified successfully");
        }

    }, [dispatch, alert, error, sideImages.length, siderImages.length, success]);


    // const submitHandler = (e) => {
    //     e.preventDefault();
    //     const formData = new FormData();

    //     if (fileSider1) {
    //         formData.set("id1", 1);
    //         formData.set("keywordSlider1", keywordSlider1);
    //         formData.append(`file`, fileSider1);
    //     }

    //     if (fileSider2) {
    //         formData.set("id2", 2);
    //         formData.set("keywordSlider2", keywordSlider2);
    //         formData.append(`file`, fileSider2);
    //     }
    //     if (fileSider3) {
    //         formData.set("id3", 3);
    //         formData.set("keywordSlider3", keywordSlider3);
    //         formData.append(`file`, fileSider3);
    //     }

    //     if (fileSider4) {
    //         formData.set("id4", 4);
    //         formData.set("keywordSlider4", keywordSlider4);
    //         formData.append(`file`, fileSider4);
    //     }

    //     dispatch(updateBanner(formData));
    // };


    // const submitHandlerSideImage = (e) => {
    //     e.preventDefault();
    //     const formData = new FormData();

    //     if (fileSideImage1) {
    //         formData.set("id1", 1);
    //         formData.set("keyword1", keyword1);
    //         formData.append(`file`, fileSideImage1);
    //     }

    //     if (fileSideImage2) {
    //         formData.set("id2", 2);
    //         formData.set("keyword2", keyword2);
    //         formData.append(`file`, fileSideImage2);
    //     }
    //     if (fileSideImage3) {
    //         formData.set("id3", 3);
    //         formData.set("keyword3", keyword3);
    //         formData.append(`file`, fileSideImage3);
    //     }

    //     dispatch(updateBannerSideImage(formData));
    // };


    //sideer images onchnge
    const onChange1 = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFileSider1(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }

            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview1(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }

    //sideer images onchnge
    const onChange2 = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFileSider2(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }

            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview2(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }

    //sideer images onchnge
    const onChange3 = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFileSider3(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }

            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview3(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }

    const onChange4 = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFileSider4(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }

            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview4(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }

    const onChange5 = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFileSider5(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }

            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview5(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }

    const onChange6 = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFileSider6(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }

            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview6(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }

    const onChange7 = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFileSider7(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }

            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview7(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }



    //side images onchnge
    const onChangeSideImage1 = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFileSideImage1(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }

            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreviewSideImage1(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }

    const onChangeSideImage2 = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFileSideImage2(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }

            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreviewSideImage2(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }

    const onChangeSideImage3 = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFileSideImage3(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }


            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreviewSideImage3(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }

    const addBannerHandler = (id, keyword, file) => {
        const formData = new FormData();
        formData.set("keyword", keyword);
        formData.append(`file`, file);

        dispatch(updateBanner(Buffer.from(id.toString(), 'binary').toString('base64'), formData));
    }

    const addHandler = (id, keyword, file) => {
        const formData = new FormData();
        formData.set("keyword", keyword);
        formData.append(`file`, file);
        dispatch(updateBannerSideImage(Buffer.from(id.toString(), 'binary').toString('base64'), formData));
    }


    const removeSliderHandler = (id) => {
        handleDialog1("Are you sure you want to delete image?", true, Buffer.from(id.toString(), 'binary').toString('base64'));

    }

    const removeSideHandler = (id) => {
        handleDialog("Are you sure you want to delete image?", true, Buffer.from(id.toString(), 'binary').toString('base64'));
    }


    const [dialog1, setDialog1] = useState({
        message: "",
        isLoading: false
    });

    const handleDialog1 = (message, isLoading, id) => {
        setDialog1({
            message,
            isLoading,
            id
        });
    };

    const areUSureDelete1 = (choose) => {
        if (choose) {
            dispatch(deleteBannerImage(dialog1.id));
            handleDialog1("", false);
        } else {
            handleDialog1("", false);
        }
    }

    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false
    });

    const handleDialog = (message, isLoading, id) => {
        setDialog({
            message,
            isLoading,
            id
        });
    };


    const areUSureDelete = (choose) => {
        if (choose) {
            dispatch(deleteSideImage(dialog.id));
            handleDialog("", false);
        } else {
            handleDialog("", false);
        }
    }


    return (
        <Fragment>
            <MetaData title={"Add Slider Images"} />
            <div className="row">
                <div className="col-12 col-md-2">
                    <Sidebar />
                </div>
                <Fragment>
                    <div className="col-12 col-md-5">
                        <div className="wrapper my-5">
                            <form
                                className="shadow-lg bannerRemove"
                            // onSubmit={submitHandler1}
                            // enctype="multipart/form-data"
                            >
                                <h1 className="mb-2">Add Slider Banner</h1>
                                <small className="banner-text">(Banner image size should be 1400px * 400px)</small>
                                <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={`/${avatarPreview1}`}
                                                key={avatarPreview1}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 1"
                                                name="keywordSlider1"
                                                value={keywordSlider1}
                                                onChange={(e) => setKeywordSlider1(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChange1}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Image 1
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 ">
                                            <button type="button" className="btn btn-danger mt-3" onClick={() => addBannerHandler(1, keywordSlider1, fileSider1)}>ADD</button>
                                        </div>
                                        <div className="col-md-1">
                                            <i className="fa fa-trash btn btn-danger mt-3 ml-4" onClick={() => removeSliderHandler(1)} ></i>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={`/${avatarPreview2}`}
                                                key={avatarPreview2}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 2"
                                                name="keywordSlider2"
                                                value={keywordSlider2}
                                                onChange={(e) => setKeywordSlider2(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChange2}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Image 2
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 ">
                                            <button type="button" className="btn btn-danger mt-3" onClick={() => addBannerHandler(2, keywordSlider2, fileSider2)}>ADD</button>
                                        </div>
                                        <div className="col-md-1">
                                            <i className="fa fa-trash btn btn-danger mt-3 ml-4" onClick={() => removeSliderHandler(2)} ></i>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={`/${avatarPreview3}`}
                                                key={avatarPreview3}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 3"
                                                name="keyword3"
                                                value={keywordSlider3}
                                                onChange={(e) => setKeywordSlider3(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChange3}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Image 3
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 ">
                                            <button type="button" className="btn btn-danger mt-3" onClick={() => addBannerHandler(3, keywordSlider3, fileSider3)}>ADD</button>
                                        </div>
                                        <div className="col-md-1">
                                            <i className="fa fa-trash btn btn-danger mt-3 ml-4" onClick={() => removeSliderHandler(3)} ></i>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={`/${avatarPreview4}`}
                                                key={avatarPreview4}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 4"
                                                name="keyword4"
                                                value={keywordSlider4}
                                                onChange={(e) => setKeywordSlider3(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChange4}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Image 4
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 ">
                                            <button type="button" className="btn btn-danger mt-3" onClick={() => addBannerHandler(4, keywordSlider4, fileSider4)}>ADD</button>
                                        </div>
                                        <div className="col-md-1">
                                            <i className="fa fa-trash btn btn-danger mt-3 ml-4" onClick={() => removeSliderHandler(4)} ></i>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={`/${avatarPreview5}`}
                                                key={avatarPreview5}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 5"
                                                name="keyword5"
                                                value={keywordSlider5}
                                                onChange={(e) => setKeywordSlider5(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChange5}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Image 5
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 ">
                                            <button type="button" className="btn btn-danger mt-3" onClick={() => addBannerHandler(5, keywordSlider5, fileSider5)}>ADD</button>
                                        </div>
                                        <div className="col-md-1">
                                            <i className="fa fa-trash btn btn-danger mt-3 ml-4" onClick={() => removeSliderHandler(5)} ></i>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={`/${avatarPreview6}`}
                                                key={avatarPreview6}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 6"
                                                name="keyword6"
                                                value={keywordSlider6}
                                                onChange={(e) => setKeywordSlider6(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChange6}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Image 6
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 ">
                                            <button type="button" className="btn btn-danger mt-3" onClick={() => addBannerHandler(6, keywordSlider6, fileSider6)}>ADD</button>
                                        </div>
                                        <div className="col-md-1">
                                            <i className="fa fa-trash btn btn-danger mt-3 ml-4" onClick={() => removeSliderHandler(6)} ></i>
                                        </div>
                                    </div>
                                </div>


                                <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={`/${avatarPreview7}`}
                                                key={avatarPreview7}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 7"
                                                name="keyword7"
                                                value={keywordSlider7}
                                                onChange={(e) => setKeywordSlider3(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChange7}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Image 7
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 ">
                                            <button type="button" className="btn btn-danger mt-3" onClick={() => addBannerHandler(7, keywordSlider7, fileSider7)}>ADD</button>
                                        </div>
                                        <div className="col-md-1">
                                            <i className="fa fa-trash btn btn-danger mt-3 ml-4" onClick={() => removeSliderHandler(7)} ></i>
                                        </div>
                                    </div>
                                </div>



                                {/* <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={avatarPreview4}
                                                key={avatarPreview4}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>

                                        <div className="col-md-5 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 4"
                                                name="keyword4"
                                                value={keywordSlider4}
                                                onChange={(e) => setKeywordSlider4(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-5 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChange4}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Choose Images
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                {/* <button
type="button"                                    id="login_button"
                                    type="submit"
                                    className="btn btn-block py-3"
                                >
                                    ADD IMAGES
                                </button> */}
                            </form>
                        </div>
                    </div>

                    <div className="col-12 col-md-5">
                        <div className="wrapper my-5">
                            <form
                                className="shadow-lg bannerRemove"
                            // onSubmit={submitHandlerSideImage}
                            // enctype="multipart/form-data"
                            >
                                <h1 className="mb-2">Add Right Side Images</h1>
                                <small className="banner-text">(Right side image size should be 700px * 500px)</small>
                                <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={`/${avatarPreviewSideImage1}`}
                                                key={avatarPreviewSideImage1}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 1"
                                                name="keyword1"
                                                value={keyword1}
                                                onChange={(e) => setKeyword1(e.target.value)}
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChangeSideImage1}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Image 1
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 ">
                                            <button type="button" className="btn btn-danger mt-3" onClick={() => addHandler(1, keyword1, fileSideImage1)}>ADD</button>
                                        </div>
                                        <div className="col-md-1">
                                            <i className="fa fa-trash btn btn-danger mt-3 ml-4" onClick={() => removeSideHandler(1)} ></i>
                                        </div>
                                        {/* <div className="col-md-1 ">
                                                <button type="button"className="btn btn-danger mt-3">ADD</button>
                                            </div> */}
                                    </div>
                                </div>

                                <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={`/${avatarPreviewSideImage2}`}
                                                key={avatarPreviewSideImage2}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 2"
                                                name="keyword2"
                                                value={keyword2}
                                                onChange={(e) => setKeyword2(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChangeSideImage2}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Image 2
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 ">
                                            <button type="button" className="btn btn-danger mt-3" onClick={() => addHandler(2, keyword2, fileSideImage2)}>ADD</button>
                                        </div>
                                        <div className="col-md-1">
                                            <i className="fa fa-trash btn btn-danger mt-3 ml-4" onClick={() => removeSideHandler(2)} ></i>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <div className="form-row" >
                                        <div className="col-md-2 mt-3">
                                            <img
                                                src={`/${avatarPreviewSideImage3}`}
                                                key={avatarPreviewSideImage3}
                                                alt="Images Preview"
                                                className="border"
                                                width="58"
                                                height="45"
                                            />
                                        </div>

                                        <div className="col-md-4 mt-3">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Keyword 3"
                                                name="keyword3"
                                                value={keyword3}
                                                onChange={(e) => setKeyword3(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-md-4 mt-3">
                                            <div className="form-group">
                                                <div className="custom-file">
                                                    <input
                                                        type="file"
                                                        name="file"
                                                        className="custom-file-input"
                                                        id="customFile"
                                                        accept='image/*'
                                                        onChange={onChangeSideImage3}
                                                    />
                                                    <label className="custom-file-label" htmlFor="customFile">
                                                        Image 3
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-1 ">
                                            <button type="button" className="btn btn-danger mt-3" onClick={() => addHandler(3, keyword3, fileSideImage3)}>ADD</button>
                                        </div>
                                        <div className="col-md-1">
                                            <i className="fa fa-trash btn btn-danger mt-3 ml-4" onClick={() => removeSideHandler(3)} ></i>
                                        </div>
                                    </div>
                                </div>

                                {/* <button
type="button"                                    id="login_button"
                                    type="submit"
                                    className="btn btn-block py-3"
                                >
                                    ADD SIDE IMAGES
                                </button> */}
                            </form>
                        </div>
                        {dialog.isLoading && (
                            <Dialog
                                onDialog={areUSureDelete}
                                message={dialog.message}
                                id={dialog.id}
                            />
                        )}

                        {dialog1.isLoading && (
                            <Dialog
                                onDialog={areUSureDelete1}
                                message={dialog1.message}
                                id={dialog1.id}
                            />
                        )}
                    </div>
                </Fragment >
            </div >

        </Fragment >
    )
}

export default BannerImages