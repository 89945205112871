import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'
import MetaData from "../layout/MetaData";
import Sidebar from "./Sidebar";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { } from "../../actions/orderActions";
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { allOrders, clearErrors, allReports } from '../../actions/orderActions'
import Loader from '../layout/Loader'
import { CSVLink } from 'react-csv';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'


const ReportReport = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const alert = useAlert();
    const [date, setDate] = useState();
    const [date1, setDate1] = useState();
    const [date2, setDate2] = useState();
    const [month, setMonth] = useState();
    const [year1, setYear1] = useState();
    const { orderRecord, loading, error, totalAmount } = useSelector(state => state.reports);
    const doc = new jsPDF()
    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const year = (new Date()).getFullYear();
    const years = Array.from(new Array(10), (val, index) => year - index);

    // if (orderRecord && Object.keys(orderRecord).length != 0) {

    //     // console.log("orderRecord", orderRecord);
    // }

    useEffect(() => {
        dispatch(allReports());
        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }
    }, [dispatch, alert, error]);

    const [list, setList] = useState({ display: "block" });
    const hide = () => {
        if (list.display === "block") {
            setList({ display: "none" })
        } else {
            setList({ display: "block" })
        }
    }

    const setOrders = () => {
        const data = {
            columns: [
                {
                    label: 'SR',
                    field: 'SR',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Order_ID',
                    field: 'id',
                    sort: 'asc',
                    width: 140
                },
                {
                    label: 'product Name',
                    field: 'PRODUCT_NAME',
                    sort: 'asc',
                    width: 260
                },
                {
                    label: 'Price',
                    field: 'SALE_PRICE',
                    sort: 'asc',
                    width: 180
                },

                {
                    label: 'Quantity',
                    field: 'QUANTITY',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'User Name',
                    field: 'USER_NAME',
                    sort: 'asc',
                    width: 120
                },
                {
                    label: 'Order Date',
                    field: 'ORDER_DATE',
                    sort: 'asc',
                    width: 210
                },
                {
                    label: 'GSTN',
                    field: 'GSTN',
                    sort: 'asc',
                    width: 210
                },
                {
                    label: 'Coupon Amount',
                    field: 'REDEEM',
                    sort: 'asc',
                    width: 120
                },
                {
                    label: 'Coupon',
                    field: 'COUPON_CODE',
                    sort: 'asc',
                    width: 140
                },
                {
                    label: 'Status',
                    field: 'ORDER_STATUS',
                    sort: 'asc',
                    width: 140
                },
                {
                    label: 'Payment',
                    field: 'PAYMENT_STATUS',
                    sort: 'asc',
                    width: 140
                },
                {
                    label: 'Tracking ID',
                    field: 'TRACKING_ID',
                    sort: 'asc',
                    width: 180
                },
                {
                    label: 'Bank Ref No',
                    field: 'BANK_REF_NO',
                    sort: 'asc',
                    width: 180
                },
                {
                    label: 'Payment Mode',
                    field: 'PAYMENT_MODE',
                    sort: 'asc',
                    width: 120
                },
                {
                    label: 'Cart',
                    field: 'CARD_NAME',
                    sort: 'asc',
                    width: 140
                },

                {
                    label: 'Total Purchase',
                    field: 'TOTAL_PRICE',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Tax Amount',
                    field: 'TAX_PRICE',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Tax(%)',
                    field: 'TAX_RATE',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Shipping Price',
                    field: 'SHIPPING_PRICE',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'List of cart qty',
                    field: 'SHOP_LENGTH',
                    sort: 'asc',
                    width: 100
                },

                {
                    label: 'Billing Name',
                    field: 'BILLING_NAME',
                    sort: 'asc',
                    width: 140
                },
                {
                    label: 'Shipping Info',
                    field: 'SHIPPING_INFO',
                    sort: 'asc',
                    width: 660
                },



            ],
            rows: []
        }

        if (orderRecord) {
            let i;
            orderRecord.forEach((order, i = 0) => {
                i++;
                data.rows.push({
                    SR: i,
                    id: order.order_id,
                    ORDER_DATE: new Date(order.order_date).toLocaleString('en-IN'),
                    ORDER_STATUS: order.orderStatus,
                    PAYMENT_STATUS: order.paymentStatus,
                    TRACKING_ID: order.tracking_id,
                    BANK_REF_NO: order.bank_ref_no,
                    PAYMENT_MODE: order.payment_mode,
                    CARD_NAME: order.card_name,
                    SALE_PRICE: order.sale_price,
                    PRODUCT_NAME: order.productName,
                    QUANTITY: order.quantity,
                    TOTAL_PRICE: order.totalPrice,
                    USER_NAME: order.userName,
                    TAX_PRICE: order.taxPrice,
                    TAX_RATE: order.tax_rate,
                    SHIPPING_PRICE: order.shippingPrice,
                    SHOP_LENGTH: order.shop_length,
                    BILLING_NAME: order.billing_name,
                    SHIPPING_INFO: `${order.flat ? order.flat + ',' : ""} ${order.area ? order.area + ',' : ""} ${order.landmark ? order.landmark + ',' : ""}
                    ${order.city ? order.city + ',' : ""} ${order.state ? order.state + ',' : ""} ${order.country ? order.country + ',' : ""} ${order.postalCode ? order.postalCode + '.' : ""} `,
                    GSTN: order.gstn,
                    REDEEM: order.redeem,
                    COUPON_CODE: order.coupon_code,

                })
            })
        }
        return data;
    }


    var csvData = JSON.parse(JSON.stringify(setOrders().rows ? setOrders().rows : []));

    const pdf = () => {
        doc.setLineWidth(5);
        doc.text(85, 15, "Orders Detailed Report");
        autoTable(doc, {
            startY: 20,
            head: [['ORDER_DATE', 'Order ID', 'Product Name', 'Price']],
            body: setOrders().rows.map((value) =>
                [value.ORDER_DATE, value.id, value.PRODUCT_NAME, value.SALE_PRICE]
            ),
            foot: [[' ', 'Total Amount', '', `${totalAmount}`]],
            headStyles: { textColor: [255, 255, 255], },
            footStyles: { textColor: [255, 255, 255], },
            theme: 'grid',
            columnStyles: {
                0: { halign: 'right', cellWidth: 30, },
                1: { halign: 'left', cellWidth: 45, },
                2: { halign: 'left', cellWidth: 70, },
                3: { halign: 'right', cellWidth: 45, }
            },
        })


        doc.save('table.pdf')
    }

    const onChange = event => {
        if (event.target.id === 'date') {
            setDate(event.target.value);
            setMonth("");
            setYear1("")
            const formData = new FormData();
            formData.set("date", event.target.value);

            var object = {};
            formData.forEach((value, key) => object[key] = value);
            var json = object
            dispatch(allReports(json));

        }

        if (event.target.id === 'date1') {
            setDate1(event.target.value)
            setDate("");
            setMonth("");
            setYear1("")
            const formData = new FormData();
            if (date2) {
                formData.set("date1", event.target.value);
                formData.set("date2", date2);

                var object = {};
                formData.forEach((value, key) => object[key] = value);
                var json = object
                dispatch(allReports(json));
            }
        }


        if (event.target.id === 'date2') {
            setDate2(event.target.value)
            setDate("");
            setMonth("");
            setYear1("")
            const formData = new FormData();
            if (date1) {
                formData.set("date1", date1);
                formData.set("date2", event.target.value);

                var object = {};
                formData.forEach((value, key) => object[key] = value);
                var json = object
                dispatch(allReports(json));
            }
        }

        if (event.target.id === 'month') {
            setMonth(event.target.value)
            setDate("");
            setYear1("")
            const formData = new FormData();
            formData.set("month", event.target.value);

            var object = {};
            formData.forEach((value, key) => object[key] = value);
            var json = object
            dispatch(allReports(json));

        }
        if (event.target.id === 'year') {
            setYear1(event.target.value)
            setMonth("");
            setDate("");

            const formData = new FormData();
            formData.set("year", event.target.value);

            var object = {};
            formData.forEach((value, key) => object[key] = value);
            var json = object
            dispatch(allReports(json));
        }
    }
    const reset = () => {
        setMonth("");
        setDate("")
        setDate1("")
        setDate2("")
        setYear1("")
        dispatch(allReports());
    }
    return (
        <Fragment>
            <MetaData title={"Order Report Download"} />
            <div className="row">
                <div style={list} className="col-12 col-md-2">
                    <Sidebar />
                </div>
                <Fragment>
                    <div className={`${list.display === 'block' ? "col-12 col-md-10" : "col-12 col-md-12"}`}>
                        <Switch color="info" defaultChecked onClick={hide} />

                        <div className="row p-3">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>From Date</label>
                                    <input
                                        type="date"
                                        id="date1"
                                        className="form-control"
                                        onChange={onChange}
                                        value={date1}
                                        max={new Date().toISOString().split('T')[0]}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label>To Date</label>
                                    <input
                                        type="date"
                                        id="date2"
                                        className="form-control"
                                        onChange={onChange}
                                        value={date2}
                                        max={new Date().toISOString().split('T')[0]}
                                        required
                                    />
                                </div>
                            </div>

                            <div className="col-md-3 d-flex align-items-center">
                                <Button
                                    variant="contained"
                                    onClick={pdf}
                                    endIcon={<i className="fa fa-file-pdf-o text-danger bg-light" aria-hidden="true"></i>}
                                    className="mr-3"
                                >
                                    PDF
                                </Button>

                                <CSVLink data={csvData} filename={"orders.csv"} target="_blank">
                                    <Button
                                        variant="contained"
                                        endIcon={<i className="fa fa-file-excel-o text-success bg-light" aria-hidden="true"></i>}
                                    >
                                        Excel
                                    </Button>
                                </CSVLink>
                            </div>

                            <div className="col-md-1 d-flex align-items-center justify-content-between">
                                <br />
                                <Button
                                    type="reset"
                                    variant="contained"
                                    value='reset'
                                    title="Reset"
                                    onClick={reset}
                                >
                                    <i className="fa fa-refresh" aria-hidden="true"></i>
                                </Button>
                            </div>
                        </div>


                        <div className="ml-2">
                            <div className="row">
                                <h1 className="my-3 col-md-7">All Orders</h1>
                                <div className='text-secondary my-4 col-md-3'><h5>Total Net Sales : Rs {String(totalAmount).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</h5> </div>
                            </div>
                            {loading ? <Loader /> : (
                                <div >
                                    <DataGrid
                                        {...setOrders()}
                                        components={{
                                            Toolbar: GridToolbar,
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </Fragment >
            </div >

        </Fragment >
    )
}

export default ReportReport