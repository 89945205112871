import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { getDiscountUI } from '../../actions/productActions';

const Product = ({ product, col }) => {
  const EID = useMemo(() => Buffer.from(`${product.id}`, 'binary').toString('base64'), [product.id]);

  return (
    <div className={`col-sm-12 col-md-6 col-lg-${col} my-3 cart-col`}>
      <div className="card p-2 rounded cart-bg">
        <div className="text-center">
          <Link to={`/product/${EID}?${encodeURIComponent(product.name)}`} target="_blank">
            <img
              className="card-img-top mx-auto"
              alt={product.category}
              src={`/${product.image}`}
              title={product.name}
              loading="lazy"
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = "/images/box.png";
              }}
            />
          </Link>

        </div>
        <div className="card-body d-flex flex-column">
          <h6 className="card-title" style={{ height: '2.8em', overflow: 'hidden' }}>
            <Link to={`/product/${EID}?${encodeURIComponent(product.name)}`} title={product.name} target="_blank">
              {product.name}
            </Link>
          </h6>

          <p >
            <span className="card-text">&#8377;<span className="fw-price">{Math.round(product.sale_price).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</span> </span>
            {product.discount > 0 && (
              <sub className="text-indianred discount-label">/-
                <del>
                  &#8377;{Math.round(getDiscountUI(product.sale_price, product.discount)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}
                </del>,
                &nbsp;<span className="cart-discount"> {product.discount}% Off</span>
              </sub>
            )}
          </p>

          <div className="ratings mb-1 text-center mt-auto">
            <div className="rating-outer">
              <div className="rating-inner" style={{ width: `${(product.ratings / 5) * 100}%` }}></div>
            </div>
            <span id="no_of_reviews">({product.numOfReviews} Reviews)</span>
          </div>

          <Link to={`/product/${EID}?${encodeURIComponent(product.name)}`} id="view_btn" className="btn btn-block" target="_blank">
            View Details
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Product;
