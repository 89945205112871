import React, { Fragment, useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css';
import { Link, useParams } from "react-router-dom";
import MetaData from "./layout/MetaData";
import CategoryMenu from "./layout/Menu";
import Product from './product/Product'
import Loader from './layout/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert';
import { getProducts, allCategory } from "../actions/productActions";
import { Carousel } from 'react-bootstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RelatedItem from "./product/RelatedItem";
import Slider1 from "react-slick";
import Checkbox from '@mui/material/Checkbox';
import { Menu } from "@mui/material";
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range)

const ProductMenu1 = () => {
    const params = useParams();
    const [currentPage, setcurrentPage] = useState(1)
    const [discountPrice, setDiscountPrice] = useState([1, 1000000])
    const { category } = useSelector(state => state.allCategory);
    const [categoryName, setCategoryName] = useState('')
    const [sort, setSort] = useState('')
    const [rating, setRating] = useState(0)
    const [btnActive, setBtnActive] = useState('');
    const [btnActive1, setBtnActive1] = useState('');
    const [btnActive2, setBtnActive2] = useState('');

    const alert = useAlert();
    const dispatch = useDispatch();
    const { loading, products, error, productsCount, resPerPage, filteredProductsCount } = useSelector(state => state.products)
    const keyword = params.keyword




    useEffect(() => {
        if (error) {
            return alert.error(error)
        }

        dispatch(getProducts(categoryName, currentPage, discountPrice, keyword, rating, sort));
        dispatch(allCategory());

    }, [dispatch, alert, error, keyword, currentPage, discountPrice, categoryName, rating, sort])

    function setCurrentPageNo(pageNumber) {
        setcurrentPage(pageNumber)
    }
    let count = productsCount;
    if (keyword) {
        count = filteredProductsCount
    }
    let settings = {
        infinite: false,
        speed: 1000,
        arrows: true,
        slidesToShow: 5,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 2,
                },
            },
        ],
    };

    const popular = () => {
        setSort('DescTop');
        setBtnActive('btn-active sort-btnactive')
        setBtnActive1('')
        setBtnActive2('')
    }
    const low = () => {
        setSort('Asc');
        setBtnActive('')
        setBtnActive1('btn-active sort-btnactive')
        setBtnActive2('')

    }
    const high = () => {
        setSort('Desc');
        setBtnActive('')
        setBtnActive1('')
        setBtnActive2('btn-active sort-btnactive')
    }


    // const products1 = {
    //     images: [{
    //         "public_id": 1,
    //         "url": "Industry Central Brochure_Final_page-0001.jpg",
    //         "title": "abc"
    //     },
    //     {
    //         "public_id": 2,
    //         "url": "Industry Central Brochure_Final_page-0002.jpg",
    //     },
    //     {
    //         "public_id": 4,
    //         "url": "Industry Central Brochure_Final_page-0004.jpg",
    //         "title": "abc"
    //     },
    //     ]
    // }

    return (
        <div className="container-fluid slider-bg">
            <Fragment>
                {loading ? <Loader /> : (
                    <Fragment>
                        <MetaData title={'Buy Best Online Industrial Tools and Safety Equipment'} />
                        {/* {keyword ? ("") : (
                            <div>
                                <div className="row">
                                    <div className="col-md-3 col-sm-12 mt-3">
                                        <CategoryMenu />
                                    </div>
                                    <div className="col-md-7 col-sm-12 mt-3">
                                        <div className="row f-flex justify-content-around">
                                            <div className="img-fluid">
                                                <Carousel >
                                                    {products1.images && products1.images.map(image => (
                                                        <Carousel.Item key={image.public_id}>
                                                            <img className="d-block banner-image" src={image.url} alt={products1.title} />
                                                        </Carousel.Item>
                                                    ))}
                                                </Carousel>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-12 mt-3">
                                        <div className="row justify-content-md-center">
                                            <div className="col">
                                                <Link to={{ pathname: "/search/drill" }}>
                                                    <img src="/1.jpg" alt="Drilling Machine" width="100%" height="110" />
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="row justify-content-md-center mt-3">
                                            <div className="col">
                                                <Link to={{ pathname: "/search/cutter" }}>
                                                    <img src="/2.jpg" alt="Cutters" width="100%" height="110" />
                                                </Link>
                                            </div>
                                        </div>

                                        <div className="row justify-content-md-center mt-3">
                                            <div className="col">
                                                <Link to={{ pathname: "/search/drill" }}>
                                                    <img src="/3.jpg" alt="Drills Machine" width="100%" height="110" />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row slider-bg">
                                    <div className="container-fluid mt-3 mb-3">
                                        <div className="bg-white related-slider"><br /><h3 className="related-heading">Top Selling Products</h3><hr /></div>
                                        <Slider1 {...settings} >
                                            {products &&
                                                products.map((product) => (
                                                    <RelatedItem key={product.id} product={product}
                                                    />
                                                ))}
                                        </Slider1>
                                    </div>
                                </div>
                            </div>
                        )} */}

                        <section id="products" className="container-fluid mt-1 bg-white">
                            {/* {keyword ? ("") : (<><br /><h1 id="products_heading">Latest Products</h1><hr /></>)} */}
                            <div className="row">
                                {keyword ? (
                                    <Fragment>
                                        <div className="col-sm-12">
                                            <div className="row">
                                                {keyword ? (
                                                    <div className="container bg-white search-margin">
                                                        <div className="row align-items-center">
                                                            <div className="col-12 col-md-2">
                                                                <h4 id="products_heading" style={{ color: "#cc3f06", marginTop: "20px", marginBottom: "0" }}>
                                                                    {keyword}
                                                                </h4>
                                                            </div>
                                                            <div className="col-12 col-md-10 filter-sort" style={{ textAlign: "right" }}>
                                                                <button type="button" className="btn" style={{ fontSize: "16px", fontWeight: "bold", fontFamily: "Arial, sans-serif", marginRight: "10px" }}>
                                                                    SORT BY
                                                                </button>
                                                                <button type="button" className={`btn sort-btn ${btnActive}`} onClick={popular} style={{ fontSize: "14px", fontFamily: "Arial, sans-serif", marginRight: "10px" }}>
                                                                    POPULARITY
                                                                </button>
                                                                <button type="button" className={`btn sort-btn ${btnActive1}`} onClick={low} style={{ fontSize: "14px", fontFamily: "Arial, sans-serif", marginRight: "10px" }}>
                                                                    LOW TO HIGH PRICE
                                                                </button>
                                                                <button type="button" className={`btn sort-btn ${btnActive2}`} onClick={high} style={{ fontSize: "14px", fontFamily: "Arial, sans-serif" }}>
                                                                    HIGH TO LOW PRICE
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>



                                                )
                                                    : ("")}
                                                {products && products.map(product => (
                                                    <Product key={product.id} product={product} col={2} />
                                                ))}
                                            </div>
                                        </div>
                                    </Fragment>
                                ) : (
                                    products && products.map(product => (
                                        <Product key={product.id} product={product} col={2} />
                                    ))
                                )}
                            </div>
                        </section>
                        {resPerPage <= count && (
                            <div className="d-flex justify-content-center mt-5">
                                <Pagination
                                    activePage={currentPage}
                                    itemsCountPerPage={resPerPage}
                                    totalItemsCount={productsCount}
                                    onChange={setCurrentPageNo}
                                    nextPageText={'Next'}
                                    prevPageText={'Prev'}
                                    firstPageText={'First'}
                                    lastPageText={'Last'}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                />
                            </div>
                        )}
                    </Fragment>
                )}
            </Fragment>
        </div>
    );
};

export default ProductMenu1;
