import React, { Fragment, useState, useEffect, useRef } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Loader from "../layout/Loader";
import MetaData from "../layout/MetaData";
import ListReviews from "../review/ListReviews";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductDetails,
  newReview,
  getRelatedProducts,
  getProductReviews1
} from "../../actions/productActions";
import { addItemToCart, addBuyItem } from "../../actions/cartActions";
import { myOrders } from '../../actions/orderActions'
import { NEW_REVIEW_RESET } from "../../constants/productConstants";
import ReadMore from "./ReadMore ";
import { useForm } from "react-hook-form";
import RelatedItem from "./RelatedItem";
import "react-medium-image-zoom/dist/styles.css";
import Slider from "react-slick";
import { removeBuyItem } from '../../actions/cartActions'
import { newEnquiry, loadUser, clearErrors } from "../../actions/userActions";
import { FaShareAlt, FaFacebookF, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import ReactImageMagnify from 'react-image-magnify';
import { getDiscountUI } from '../../actions/productActions'
import { isMobile } from 'react-device-detect';


const ProductDetails = () => {
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const location = useLocation();
  const params = useParams();
  const [quantity, setQuantity] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [img, setImg] = useState([]);
  const dispatch = useDispatch();
  const alert = useAlert();
  const { register, handleSubmit, formState: { errors }, reset, trigger, } = useForm();
  const { loading, error, product, productImages, productDetails, productProperties } = useSelector((state) => state.productDetails);
  const { reviews } = useSelector(state => state.productReviews);
  const { cartItems } = useSelector(state => state.cart);
  const { success: sss } = useSelector(state => state.enquiry);
  const { user } = useSelector((state) => state.auth);
  const { error: reviewError, success } = useSelector((state) => state.newReview);
  const { productsRelated } = useSelector((state) => state.productsRelated);
  const { buyItem } = useSelector(state => state.buy);
  const { orders } = useSelector(state => state.myOrders);
  const pCategory = product.category;
  const images = JSON.parse(JSON.stringify(productImages))
  const [pName, setPName] = useState(product?.name);
  const [qty, setQty] = useState();
  let purchased = false;

  if (orders) {
    purchased = orders.some(order => order.product_id === Buffer.from(params.id, 'base64').toString('binary'));
  }

  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  const arr = [];
  if (productProperties) {
    for (const item of productProperties) {
      arr.push(item.properties1, item.properties2, item.properties3);
    }
  }


  // console.log("arr", arr);

  const arr1 = productProperties ? productProperties.map(item => item.value1) : [];
  const arr2 = productProperties?.map(item => item.value2) || [];
  const arr3 = productProperties ? productProperties.map(item => item.value3) : [];
  const arrID = productProperties ? productProperties.map(item => item.id) : [];

  const [isVisible, setIsVisible] = useState(false);
  const currentUrl = window.location.href;

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  // For hiding table in product details
  const [r, setR] = useState('r');
  const hide = () => {
    setR(prevState => (prevState === "r" ? "p" : "r"));
  };


  //image zoom
  const hoverHandler = (image, i) => {
    setImg(image);
    refs.current.forEach((ref, j) => {
      if (ref) {
        if (i === j) {
          ref.classList.add('activeimg');
        } else {
          ref.classList.remove('activeimg');
        }
      }
    });
  };

  const refs = useRef([]);
  refs.current = [];
  const addRefs = (el) => {
    if (el && !refs.current.includes(el)) {
      refs.current.push(el);
    }
  };
  const ref = useRef(null);

  const EID = Buffer.from(`${product.id}`, 'binary').toString('base64')

  useEffect(() => {
    if (!EID || EID !== params.id) {
      dispatch(getProductDetails(params.id))
      dispatch(getProductReviews1(params.id))
    }
    if (images.length !== 0) {
      setImg([images[0].imageName]);
    }
    if (pCategory) {
      dispatch(getRelatedProducts(pCategory));
    }
    if (error) {
      if (error === 'Product not found') {
        navigate('/404', { replace: true });
      }
      alert.error(error);
    }

    if (reviewError) {
      alert.error(reviewError);
    }
    if (success) {
      alert.success("Review posted successfully");
      dispatch({ type: NEW_REVIEW_RESET });
    }
    if (sss) {
      alert.success('Request has been send successfully');
    }
    dispatch(myOrders());

    if (user) {
      setName(user.name);
      setMobile(user.mobile);
      setEmail(user.email);
    }
    if (product) {
      setPName(product.name);
      setQty(quantity * product?.bulk_qty)
    }

    const body = document.querySelector('#root');

    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)

  }, [
    dispatch,
    navigate,
    pCategory,
    EID,
    alert,
    error,
    reviewError,
    params.id,
    success,
    sss
  ]);

  const addToCart = () => {
    const paramID = Buffer.from(params.id.toString(), 'base64').toString('binary');

    if (cartItems.length < 10) {
      let count = 0;
      cartItems.map((item) => {
        if (Number(paramID) === item.product) {
          count = item.quantity;
        }
      });

      if (count) {
        if ((quantity + count) <= 30) {
          dispatch(addItemToCart(params.id, ((quantity * product?.bulk_qty) + count)));
          alert.success("Item Added to Cart");
        } else {
          alert.success("Maximum limit of add to cart exceed");
        }

      } else {
        dispatch(addItemToCart(params.id, quantity * product?.bulk_qty));
        alert.success("Item Added to Cart");
      }

    } else {
      alert.success("Cart has touched the max limit. Please delete existing cart items to add a new item. ");
    }
  };

  const addToBuy = () => {
    remove();
    dispatch(addBuyItem(params.id, quantity * product?.bulk_qty));
    navigate('/shipping', { state: { prevPath: location.pathname } });
  }

  const remove = () => {
    if (buyItem) {
      buyItem.map(item => (
        dispatch(removeBuyItem(item.product))))
    }
  }
  const increaseQty = () => {
    const count = document.querySelector(".count");
    if (count.valueAsNumber >= 30) {
      alert.error("Maximum Orderable Quantity Should be 30")
    } else {
      const addStocks = product?.bulk_qty * (quantity + 1);
      if (count.valueAsNumber >= product.stock || addStocks >= product.stock) {
        alert.error(`${product.name} available stock is ${product.stock}`);
        return;
      };

      setQuantity(quantity + 1);

    }
  };

  const decreaseQty = () => {
    const count = document.querySelector(".count");
    if (count.valueAsNumber <= 1 || (quantity * product?.bulk_qty) <= product?.bulk_qty) return;
    setQuantity(quantity - 1);
  };

  function setUserRatings() {
    const stars = document.querySelectorAll(".star");
    stars && stars.forEach((star, index) => {
      star.starValue = index + 1;

      ["click", "mouseover", "mouseout"].forEach(function (e) {
        star.addEventListener(e, showRatings);
      });
    });

    function showRatings(e) {
      stars.forEach((star, index) => {
        if (e.type === "click") {
          if (index < this.starValue) {
            star.classList.add("orange");
            setRating(this.starValue);
          } else {
            star.classList.remove("orange");
          }
        }
        if (e.type === "mouseover") {
          if (index < this.starValue) {
            star.classList.add("yellow");
          } else {
            star.classList.remove("yellow");
          }
        }
        if (e.type === "mouseout") {
          star.classList.remove("yellow");
        }
      });
    }
  }
  const reviewHandler = () => {

    if (rating && comment.length !== 0) {
      const formData = new FormData();
      formData.set("rating", rating);
      formData.set("comment", comment);
      formData.set("productId", params.id);

      var object = {};
      formData.forEach((value, key) => object[key] = value);
      var json = object

      dispatch(newReview(json));
    } else {
      window.alert("Product Rating & Comment Cannot Empty...!!!");
    }
  }
  let settings = {
    infinite: true,
    speed: 1000,
    arrows: true,
    slidesToShow: 6,
    autoplay: true,
    slidesToScroll: 6,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          speed: 1000,
          infinite: true,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 1000,
          infinite: true,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };


  function stockZero() {
    if (product.stock === 0) {
      alert.error("Currently product out of stock")
    }
  }

  const handleClick = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }

  function restefiled() {
    setName("")
    setMobile()
    setEmail("")
    setMessage("")
    setPName("")
    setQuantity("")
  }
  function submit(data, e) {
    e.preventDefault();
    const formData = new FormData();
    formData.set("name", name);
    formData.set("mobile", mobile);
    formData.set("email", email);
    formData.set("pName", pName);
    formData.set("quantity", qty);
    formData.set("product_id", product?.id);
    formData.set("message", message);
    formData.set("user_id", user?.id);
    formData.set("user_name", user?.name);

    var object = {};
    formData.forEach((value, key) => object[key] = value);
    var json = object

    dispatch(newEnquiry(json));
    reset();
  }

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={product.name} />
          <div className="container-fluid">

            <div className="container-fluid">
              <div className="row d-flex justify-content-around">
                <div className="col-sm-12 col-lg-5 img-fluid" id="product_image">
                  <div className="containerZoom">
                    <div className="leftZoom">
                      <div className="left_1Zoom">
                        {images && images.map((image, i) => (
                          <div
                            className={i === 0 ? 'img_wrapZoom activeimg' : 'img_wrapZoom'}
                            key={i}
                            id='classList'
                            onMouseOver={() => hoverHandler(image.imageName, i)}
                            ref={addRefs}
                          >
                            <img src={image.imageName} alt="" loading="lazy" onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = "/images/box.png";
                            }} />
                          </div>
                        ))}
                      </div>
                      <div className="left_2Zoom ">
                        <ReactImageMagnify
                          {...{
                            smallImage: {
                              alt: img.toString(),
                              src: img.toString(),
                              width: 450,
                              height: isMobile ? 200 : 400,
                              sizes: "(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px"
                            },
                            largeImage: {
                              src: img.toString(),
                              isFluidWidth: true,
                              width: 1200,
                              height: 1180
                            },
                            enlargedImageContainerDimensions: {
                              width: '162%',
                              height: '120%'
                            },
                            enlargedImageContainerStyle: { background: '#fff', zIndex: 9 }
                          }}
                        />
                      </div>
                    </div>
                    <div className="right"></div>
                  </div>
                </div>

                <div className="col-sm-12 col-lg-6 mt-3 pd-scroll">
                  <div className="share-button-container">
                    {!isVisible && <span className="text-secondary">Share on </span>}
                    <button title='Share on' className="share-icon" onClick={toggleVisibility}>
                      <FaShareAlt />
                    </button>
                    {isVisible && (
                      <div className="social-icons">
                        <a className="whatsapp" href={`https://api.whatsapp.com/send?text=Check%20this%20out:%20${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                          <FaWhatsapp />
                        </a>
                        <a className="facebook" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                          <FaFacebookF />
                        </a>
                        <a className="linkedin" href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}`} target="_blank" rel="noopener noreferrer">
                          <FaLinkedinIn />
                        </a>
                      </div>
                    )}
                  </div>
                  <br />
                  <h4 class="product-title my-4">{product.name}</h4>
                  <hr className="hr-productDetails" />
                  <p>
                    {" "}
                    <span className="sale_price" id="product_price">
                      &#8377;{Math.round((product.sale_price)).toLocaleString('en-IN', { maximumFractionDigits: 2 })}{" "}
                    </span>
                    <span>
                      {product.discount > 0 ? (
                        <span className="text-secondary discount-label">
                          /-<del>&#8377;{getDiscountUI(product.sale_price, product.discount).toLocaleString('en-IN', { minimumFractionDigits: 0, maximumFractionDigits: 0 })}</del>
                          &nbsp;<span className="cart-discount">
                            {" "}
                            {product.discount}% off
                          </span>
                          {product?.bulk_qty > 1 ? (
                            <span className="inline-block my-2">
                              <div className="text-dark">
                                Bulk order prices require multiples of <b>{product?.bulk_qty}</b>.</div>
                            </span>
                          ) : ""}
                        </span>
                      ) : (
                        ""
                      )}
                    </span>
                  </p>
                  <div className="stockCounter d-inline">
                    <div>
                      <span className="btn minus-btn" onClick={decreaseQty}>
                        -
                      </span>
                      <input
                        type="number"
                        className="form-control count d-inline"
                        value={quantity * product?.bulk_qty}
                        readOnly
                      />
                      <span className="btn plus-btn" onClick={increaseQty}>
                        +
                      </span>
                      <span>
                      </span>
                    </div>


                    <span>
                      {product.stock < 6 && product.stock > 0 ? (
                        <span className="inline-block mr-3">
                          <div className="text-danger d-inline  ml-4">Hurry, Only {product.stock} left</div>
                        </span>
                      ) : ("")}

                    </span>
                  </div>

                  <div className="row">
                    <button
                      type="button"
                      id="cart_btn"
                      className="btn btn-primary d-inline ml-3 mt-4"
                      disabled={product.stock === 0}
                      onClick={addToCart}
                    >
                      <span onClick={stockZero}>Add to Cart</span>
                    </button>

                    <button
                      type="button"
                      id="cart_btn"
                      className="btn btn-primary d-inline ml-3 mt-4"
                      disabled={product.stock === 0}
                      onClick={addToBuy}
                    >
                      <span onClick={stockZero}> Buy Now </span>
                    </button>
                  </div>

                  {product.product_code ? (
                    <div className="row">
                      <hr className="hr-productDetails" />
                      {
                        arr[0] &&
                        <div>
                          <h5 className="ml-3">Select {arr[0]}</h5>
                          <ul id="menu">
                            <div className="selected">
                              {arr1.filter(onlyUnique).map((ele, i = 0) => (
                                (arr1[i] && (<Link to={`/product/${Buffer.from(`${arrID[i]}`, 'binary').toString('base64')}`}>
                                  <li className={`${product.value1 === arr1[i] ? 'list-active' : ""}`}>{arr1[i++]}</li></Link>))
                              ))}
                            </div>
                          </ul><br /><br />
                        </div>
                      }

                      {
                        arr[1] &&
                        <div>
                          <h5 className="ml-3">Select {arr[1]}</h5>
                          <ul id="menu">
                            <div className="selected">
                              {arr2.filter(onlyUnique).map((ele, i = 0) => (
                                (arr2[i] && (<Link to={`/product/${Buffer.from(`${arrID[i]}`, 'binary').toString('base64')}`}>
                                  <li className={`${product.value2 === arr2[i] ? 'list-active' : ""}`}>{arr2[i++]}</li></Link>))
                              ))}
                            </div>
                          </ul><br /><br />
                        </div>
                      }

                      {
                        arr[2] &&
                        <div>
                          <h5 className="ml-3">Select {arr[2]}</h5>
                          <ul id="menu">
                            <div className="selected">
                              {arr3.filter(onlyUnique).map((ele, i = 0) => (
                                (arr3[i] && (<Link to={`/product/${Buffer.from(`${arrID[i]}`, 'binary').toString('base64')}`}>
                                  <li className={`${product.value3 === arr3[i] ? 'list-active' : ""}`}>{arr3[i++]}</li></Link>))
                              ))}
                            </div>
                          </ul>
                        </div>
                      }
                    </div>
                  ) : ("")}

                  <div>
                    <hr className="hr-productDetails" />
                    <ul className="list-unstyled list-group list-group-horizontal py-2">
                      <li className="inline-block mr-3">
                        <b>Status:</b>{" "}
                        <span
                          id="stock_status"
                          className={product.stock > 0 ? "greenColor" : "redColor"}
                        >
                          {product.stock > 0 ? "In Stock" : "Out of Stock"}
                        </span>

                      </li>
                      <li>
                        {/* <Link to='/enquiry1' state={{ productName: product.name, quantity: quantity, product_id: product.id }} className="text-dark btn btn-light btn-bulk">Bulk Order Enquiry</Link> */}

                        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
                          Bulk Order
                        </button>

                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog" role="document">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel">BULK ORDER ENQUIRY FORM</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <form onSubmit={handleSubmit(submit)}>
                                  <div className="row enquiryForm">
                                    <div className="col-md-5 form-group">
                                      <label>Customer  Name:-</label>
                                      <input name="Name" type="text" className="form-control in-from" id="Name"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                        placeholder="Name:" />
                                    </div>
                                    <div className="col-md-7 form-group">
                                      <label>Contact No:-</label>
                                      <input type="text" name="Mobile" id="mobile"
                                        className={`form-control ${errors.mobile && "invalid"}`}
                                        {...register("mobile", {
                                          pattern: {
                                            value: /^(?:(?:\+|0{0,2})91(\s*[-]\s*)?|[0]?)?[789]\d{9}$/g,
                                            message: "Invalid Mobile Number",
                                          },
                                          maxLength: {
                                            value: 15,
                                            message: "Invalid Mobile Number",
                                          }
                                        })}
                                        onSubmit={() => {
                                          trigger("mobile");
                                        }}
                                        value={mobile}
                                        required
                                        onChange={(e) => setMobile(e.target.value)}
                                        placeholder="Mobile:"
                                      />
                                      {errors.mobile && (
                                        <small className="text-danger">{errors.mobile.message}</small>
                                      )}
                                    </div>
                                  </div>
                                  <div className="row enquiryForm">
                                    <div className="col-md-7 form-group">
                                      <label>Email ID:-</label>
                                      <input type="text" name="Email" id="email"
                                        className={`form-control ${errors.email_field && "invalid"}`}
                                        {...register("email", {
                                          pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid Email ID",
                                          }
                                        })}
                                        required
                                        onSubmit={() => {
                                          trigger("email");
                                        }}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Email:" />
                                      {errors.email && (
                                        <small className="text-danger">{errors.email.message}</small>
                                      )}
                                    </div>

                                    <div className="col-md-5 form-group">
                                      <label>Product Quantity</label>
                                      <input type="number" name="qty" id="qty"
                                        className="form-control"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                        placeholder="Product Quantity:" />
                                    </div>
                                  </div>

                                  <div className="row enquiryForm">
                                    <div className="col form-group">
                                      <label>Product Name:-</label>
                                      <input type="text" name="pname" id="pname"
                                        className="form-control"
                                        value={pName}
                                        onChange={(e) => setPName(e.target.value)}
                                        placeholder="Product Name:" />
                                    </div>
                                  </div>

                                  <div className="row enquiryForm">
                                    <div className="col form-group" >
                                      <label>Message</label>
                                      <textarea name="Address" id="message" rows="2"
                                        className={`form-control ${errors.message && "invalid"}`}
                                        {...register("message", {
                                          minLength: {
                                            value: 5,
                                            message: "Minimum Required Length Should Be 5",
                                          },
                                          maxLength: {
                                            value: 500,
                                            message: "Maximum Required Length Should Be 500 ",
                                          }
                                        })}
                                        onSubmit={() => {
                                          trigger("message");
                                        }}
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder="Message : "></textarea>
                                      {errors.message && (
                                        <small className="text-danger">{errors.message.message}</small>
                                      )}
                                    </div>
                                  </div>
                                  <div class="modal-footer">
                                    <button type="reset" onClick={restefiled} className="btn btn-danger in-from ml-3" >Reset&nbsp;</button>
                                    <button type="submit" class="btn btn-primary">Submit</button>
                                  </div>
                                </form>
                              </div>

                            </div>
                          </div>
                        </div>

                      </li>
                    </ul>

                  </div>

                  {product.description ? (<div><hr className="hr-productDetails" /><h5 className="prod-heading mt-2">Key Features </h5>
                    <span>
                      <ReadMore text={(String(product.description))} />
                    </span>
                    <hr className="hr-productDetails" />
                  </div>) : ("")}

                  <div className="rating-outer">
                    <div
                      className="rating-inner"
                      style={{ width: `${(product.ratings / 5) * 100}%` }}
                    ></div>
                  </div>
                  <Link to="#" onClick={handleClick} id="no_of_reviews">({product.numOfReviews} Reviews)</Link>
                  <hr className="hr-productDetails" />

                  {product.seller ? (<p id="product_seller mb-3">
                    Sold by: <strong>{product.seller}</strong>
                  </p>) : ("")}
                  <br />
                  <div className="row mt-2 mb-5">
                    {productDetails.length ? (
                      <table className="table table-striped " id="Table_id">
                        <thead>
                          <tr>
                            <th scope="col" colSpan="2"> <h5 className="prod-heading">Product Specification </h5></th>
                          </tr>
                        </thead>
                        <tbody>
                          {productDetails.length &&
                            productDetails.map((field, i) => (
                              <tr className={`${r}${i + 1}`} id={i + 1}>
                                <td className="text-muted">{field.title}</td>
                                <td >{field.description}</td>
                              </tr>
                            ))}
                          <tr>
                            <td colSpan="2" className="pt-2 pb-2" align="left"><a onClick={hide} className="text-muted">
                              {r === "r" ? (<div><small>SHOW MORE </small><b >&#8743;</b></div>) : (<div><small>SHOW LESS </small><b >&#8744;</b></div>)}</a></td>
                          </tr>
                        </tbody>
                      </table>
                    ) : ("")}
                  </div>
                  {/* {productDetails.length && (<CustomPaginationActionsTable productDetails={productDetails} />)} */}
                  <div><h5 className="prod-heading mt-2">Reviews </h5><hr className="hr-productDetails" />
                    {user ? (
                      purchased === true ? (<button
                        id="review_btn"
                        type="button"
                        className="glow-on-hover btn btn-primary"
                        data-toggle="modal"
                        data-target="#ratingModal"
                        onClick={setUserRatings}
                      >
                        Write a review
                      </button>) : (
                        <div className="alert alert-warning mt-5" type="alert">
                          Haven't purchased this product?<br />
                          Sorry! You are not allowed to review this product since you haven't bought it on Indicent Bulk Supply Pvt. Ltd.
                        </div>
                      )

                    ) : (
                      <div className="alert alert-danger mt-5" type="alert">
                        Login to post your review.
                      </div>
                    )}
                  </div>


                  <div className="row">
                    <div className="rating w-50">
                      <div
                        className="modal fade"
                        id="ratingModal"
                        tabIndex="-1"
                        role="dialog"
                        aria-labelledby="ratingModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5 className="modal-title" id="ratingModalLabel">
                                Submit Review
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                data-close
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <ul className="stars">
                                <li className="star">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="star">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="star">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="star">
                                  <i className="fa fa-star"></i>
                                </li>
                                <li className="star">
                                  <i className="fa fa-star"></i>
                                </li>
                              </ul>
                              <textarea
                                name="review"
                                id="review"
                                className="form-control mt-3"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                              ></textarea>
                              <button
                                className="btn my-3 float-right review-btn px-4 text-white"
                                onClick={reviewHandler}
                                data-dismiss="modal"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <div ref={ref}>
                {reviews && reviews.length > 0 && (
                  <ListReviews reviews={reviews} />
                )}
              </div>
              <div className="m-3 position-relative">
                <h3 className="similar-products-heading">Similar products</h3>
                <hr className="hr-productDetails" />
                {Object.keys(product).length === 0 ? (
                  <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <Slider {...settings}>
                    {productsRelated &&
                      productsRelated.map((prod) => (
                        prod.id === EID ? ("") : (< RelatedItem key={prod.id} product={prod} />)

                      ))}
                  </Slider>
                )}
              </div>
            </div>
          </div>
          {/* {(products && products.map(product => (
                  <RelatedItem key={EID} product={product}  />
                )))} */}

          {/* {(productsRelated && productsRelated.map(rProduct => (
                  <RelatedItem key={rEID} rProduct={rProduct}  />
                )))} */}

          {/* {(productsRelated && <RelatedItem key={productsRelated.id} productsRelated={productsRelated}  />)} */}

          {/* <RelatedItem key={EID} product={product} /> */}
        </Fragment >
      )
      }
    </Fragment >
  );
};

export default ProductDetails;
