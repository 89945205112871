import React, { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'

import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader'
import Dialog from "../../Dialog";
import { useAlert } from 'react-alert'
import { useNavigate, useLocation, } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import { saveShippingInfo } from "../../actions/cartActions";
import { allAddress, deleteAddress, defaultAddress, clearErrors } from '../../actions/userActions'
import { DELETE_ADDRESS_RESET, DEFAULT_ADDRESS_SUCCESS } from '../../constants/userConstants'

const MyAddress = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const alert = useAlert();
    const dispatch = useDispatch();
    const [confirmId, setconfirmId] = useState();
    const { loading, error, address } = useSelector(state => state.allAddress);
    const { isDeleted, isUpdated } = useSelector(state => state.address);
    const { success } = useSelector(state => state.addressDetails);
    const { user } = useSelector((state) => state.auth);
    const { isDefault } = useSelector((state) => state.defaultAddress);

    useEffect(() => {
        dispatch(allAddress(user.id));

        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }

        if (isDeleted) {
            // if (cartProb === "shipping") {
            //     window.location.href = "/shipping";
            //     alert.success('Address deleted successfully');
            // } else {
            navigate('/me/update/myaddress', { state: location.state });
            alert.success('Address has been deleted successfully');
            dispatch({ type: DELETE_ADDRESS_RESET })
            // }
        }
        if (isDefault) {
            // if (cartProb === "shipping") {
            //     window.location.href = "/shipping";
            //     alert.success('Address is set as default');
            // } else {
            navigate('/me/update/myaddress', { state: location.state });
            alert.success('Address has been set as default');
            dispatch({ type: DEFAULT_ADDRESS_SUCCESS })
        }

        setconfirmId(location.state);
        // if (isUpdated) {
        //     history.push('/me/update/myaddress');
        //     alert.success('Address Updated Successfully');
        //     dispatch({ type: UPDATE_ADDRESS_RESET })
        // }
        // if (success) {
        //     console.log("success1");
        //     history.push('/me/update/address');
        //     alert.success('Category Added Successfully');
        //     dispatch({ type: ADD_ADDRESS_REQUEST })
        // }
    }, [dispatch, alert, error, isDeleted, user.id, location, isDefault, success, isUpdated])


    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        //Update
        nameProduct: ""
    });

    const handleDialog = (message, isLoading, nameProduct, id) => {
        setDialog({
            message,
            isLoading,
            //Update
            nameProduct,
            id
        });
    };

    const deleteAddressHandler = (id, name) => {
        handleDialog("Are you sure you want to delete address?", true, name, id);
    }
    const areUSureDelete = (choose) => {
        if (choose) {
            dispatch(deleteAddress(dialog.id))
            handleDialog("", false);
        } else {
            handleDialog("", false);
        }
    }

    const defaultAddressHandler = (id) => {
        dispatch(defaultAddress(id))
    }
    const submitContinue = () => {
        if (address) {
            address.map(add => {
                if (add.main) {
                    var name = add.name
                    var mobile = add.mobile
                    var flat = add.flat
                    var area = add.area
                    var landmark = add.landmark
                    var city = add.city
                    var state = add.state
                    var country = add.country
                    var postalCode = add.postalCode
                    dispatch(saveShippingInfo({ name, mobile, flat, area, landmark, city, state, country, postalCode }));
                    navigate('/confirm');
                }
                return add;
            })
        }
    }
    if (address) {
        address.map(add => {
            if (add.main) {
                var name = add.name
                var mobile = add.mobile
                var flat = add.flat
                var area = add.area
                var landmark = add.landmark
                var city = add.city
                var state = add.state
                var country = add.country
                var postalCode = add.postalCode
                dispatch(saveShippingInfo({ name, mobile, flat, area, landmark, city, state, country, postalCode }));
            }
            return add;
        })
    }

    const setAddress = () => {
        const data = {
            columns: [
                {
                    label: 'SR. ID',
                    field: 'id',
                    sort: 'asc'
                },
                {
                    label: 'ADDRESESS',
                    field: 'name',
                    sort: 'asc'
                },
                {
                    label: 'ACTIONS',
                    field: 'actions',
                },
            ],
            rows: []
        }

        address.forEach((add, count = 0) => {
            const EID = Buffer.from(add.id.toString(), 'binary').toString('base64')
            data.rows.push({
                id: count + 1,
                name: (add.name ? add.name + ", " : "") + (add.mobile ? add.mobile + ", " : "") + (add.flat ? add.flat + ", " : "")
                    + (add.area ? add.area : "") + "\n" + (add.landmark ? add.landmark : "") + ", " + (add.city ? add.city + ", " : "")
                    + (add.state ? add.state + ", " : "") + (add.country ? add.country + ", " : "") + (add.postalCode ? add.postalCode : ""),
                actions: <Fragment>
                    <button onClick={() => { window.location.href = `/me/update/myaddress/${EID}` }}
                        title="Edit Address" className="btn btn-primary py-1 px-2">
                        <i className="fa fa-pencil"></i>
                    </button>

                    <button className="btn btn-danger py-1 px-2 ml-2" onClick={() => deleteAddressHandler(EID, add.address)}
                        title="Delete Address">
                        <i className="fa fa-trash"></i>
                    </button>
                    {add.main ? (<button className="btn btn-info py-1 px-2 ml-2" title="Select as Delivery Address" onClick={() => defaultAddressHandler(EID)} >
                        <i className="fa fa-home" aria-hidden="true"></i>
                    </button>) : (
                        <button className="btn btn-light py-1 px-2 ml-2" title="Select as Home Address" onClick={() => defaultAddressHandler(EID)} >
                            <i className="fa fa-home" aria-hidden="true"></i>
                        </button>
                    )}

                </Fragment>
            })
        })
        return data;
    }

    return (
        <Fragment>
            <MetaData title={'Manage Addresses'} />
            <Fragment>
                <div className='m-5 m-address'>
                    <h1 className="my-5">Your Addresses</h1>
                    <div className='row'>
                        <div className='col-md-3 col-sm-12 form-group'>
                            <Link to={`/me/update/address/new`} state={location.state} className='my-2' >
                                <input className='btn btn-default btn-block btn-lg my-0 float-right  review-btn px-4 text-white' value="ADD NEW ADDRESS" />
                            </Link>
                        </div>
                        {confirmId ? (
                            <div className='col-md-9 col-sm-12 form-group'>
                                <input className='btn btn-default btn-lg my-0 float-right  review-btn px-2 text-white' onClick={submitContinue} value="CONFIRM ORDER" />
                            </div>) :
                            ("")}
                    </div>

                    {loading ? <Loader /> : (
                        <MDBDataTable
                            data={setAddress()}
                            className="px-3"
                            bordered
                            striped
                            hover
                        />
                    )}
                </div>
            </Fragment>
            {dialog.isLoading && (
                <Dialog
                    //Update
                    nameProduct={dialog.nameProduct}
                    onDialog={areUSureDelete}
                    message={dialog.message}
                    id={dialog.id}
                />
            )}
        </Fragment>
    )
}

export default MyAddress
