import React, { Fragment, useState, useEffect } from "react";
import Pagination from "react-js-pagination";
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css';
import { Link, useParams } from "react-router-dom";
import MetaData from "./layout/MetaData";
import Product from './product/Product'
import Loader from './layout/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert';
import { getProducts, allCategory } from "../actions/productActions";
import Checkbox from '@mui/material/Checkbox';
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range)

const HomeFind = () => {
  const params = useParams();
  const [currentPage, setcurrentPage] = useState(1)
  const [discountPrice, setDiscountPrice] = useState([1, 1000000])
  const { category } = useSelector(state => state.allCategory);
  const [categoryName, setCategoryName] = useState('')
  const [sort, setSort] = useState('')
  const [rating, setRating] = useState(0)
  const [btnActive, setBtnActive] = useState('');
  const [btnActive1, setBtnActive1] = useState('');
  const [btnActive2, setBtnActive2] = useState('');

  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, products, error, productsCount, resPerPage, filteredProductsCount } = useSelector(state => state.products)
  const keyword = params.keyword

  useEffect(() => {
    if (error) {
      return alert.error(error)
    }

    dispatch(getProducts(keyword, currentPage, discountPrice, categoryName, rating, sort));
    dispatch(allCategory());

  }, [dispatch, alert, error, keyword, currentPage, discountPrice, categoryName, rating, sort])

  function setCurrentPageNo(pageNumber) {
    setcurrentPage(pageNumber);
    scrollTop()
  }
  let count = productsCount;
  if (keyword) {
    count = filteredProductsCount
  }

  const popular = () => {
    setSort('DescTop');
    setBtnActive('btn-active sort-btnactive')
    setBtnActive1('')
    setBtnActive2('')
  }
  const low = () => {
    setSort('Asc');
    setBtnActive('')
    setBtnActive1('btn-active sort-btnactive')
    setBtnActive2('')

  }
  const high = () => {
    setSort('Desc');
    setBtnActive('')
    setBtnActive1('')
    setBtnActive2('btn-active sort-btnactive')
  }

  function scrollTop() {
    const body = document.querySelector('#root');
    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)
  }

  return (
    <div className="container-fluid slider-bg">
      <Fragment>
        {loading ? <Loader /> : (
          <Fragment>
            <MetaData title={'Buy Online for Industrial Tools, Safety Equipment, Power Tools & more - Industry Central'} content="Your page description" />

            <section id="products" className="container-fluid mt-1 bg-white">
              <div className="row">
                {keyword ? (
                  <Fragment>
                    <div className="col-sm-12 col-md-3 filter-apply my-5">
                      <div className="px-4 f1">
                        <Range
                          marks={{ 100: `₹100`, 50000: `₹50000` }}
                          min={100}
                          max={50000}
                          defaultValue={[100, 50000]}
                          tipFormatter={value => `Rs.${value}`}
                          tipProps={{ placement: "top", visible: true }}
                          value={discountPrice}
                          onChange={discountPrice => setDiscountPrice(discountPrice)}
                        />
                        <hr className="my-5" />
                        <div className="mt-5 scroll" >
                          <h4 className="mb-3">Categories</h4>
                          <ul className="pl-0">
                            {category.map(cat => (
                              <li style={{ cursor: 'pointer', listStyleType: 'none' }}>
                                <Checkbox key={cat.category} value={categoryName} onChange={() => setCategoryName(cat.category)} color="success" defaultChecked={cat.category === categoryName ? true : false} />
                                {cat.category}
                              </li>
                            ))}
                          </ul>
                        </div>
                        {/* ratings */}
                        <hr className="my-3" />
                        <div className="mt-5" >
                          <h4 className="mt-3">Select Ratings</h4>
                          <ul className="pl-0">
                            {[5, 4, 3, 2, 1].map(star => (
                              <li
                                style={{ cursor: 'pointer', listStyleType: 'none' }} className="rating-size" key={star} onClick={() => setRating(star)} >
                                <div className="rating-outer">
                                  <div className="rating-inner" style={{ width: `${star * 20}%` }}>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div className="mt-5 ml-3" >
                          <button type="reset" className="btn btn-danger" onClick={() => {
                            window.location.reload()
                          }}>Reset</button>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-9">
                      <div className="row">
                        {keyword ? (
                          <div className="row container">
                            <div className="bg-white serach-margin">
                              <div className="ml-2">
                                <h1 id="products_heading" className="serach-result">1-{filteredProductsCount} of over {productsCount} results for
                                  <span style={{ color: "#cc3f06" }}> "{keyword}"</span> </h1>
                                <hr />

                                <div className="filter-sort">
                                  <div className="text-right">
                                    <button type="button" className="btn"><b>SORT BY</b></button>
                                    <button type="button" className={`btn sort-btn ${btnActive}`} onClick={popular}>POPULARITY</button>
                                    <button type="button" className={`btn sort-btn ${btnActive1}`} onClick={low}>LOW TO HIGH PRICE</button>
                                    <button type="button" className={`btn sort-btn ${btnActive2}`} onClick={high}>HIGH TO LOW PRICE</button>
                                  </div>
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>
                        )
                          : ("")}
                        {products && products.map(product => (
                          <Product key={product.id} product={product} col={3} />
                        ))}
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  products && products.map(product => (
                    <Product key={product.id} product={product} col={2} />
                  ))
                )}
              </div>
            </section>
            {resPerPage <= count && (
              <div className="d-flex justify-content-center mt-5">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={resPerPage}
                  totalItemsCount={productsCount}
                  onChange={setCurrentPageNo}
                  nextPageText={'Next'}
                  prevPageText={'Prev'}
                  firstPageText={'First'}
                  lastPageText={'Last'}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

export default HomeFind;
