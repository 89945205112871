import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'
import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { cancelOrder, returnOrder, myOrders, invoicePdf, clearErrors } from '../../actions/orderActions'
import Dialog1 from "../../Dialog1";
import Dialog2 from "../../Dialog2";
import { CANCEL_ORDER_RESET, RETURN_ORDER_RESET } from '../../constants/orderConstants'
import { cancelReason } from '../../Dialog1'
import { returnReason } from '../../Dialog2'

const ListOrders = () => {
    const navigate = useNavigate();
    const alert = useAlert();
    const dispatch = useDispatch();
    const { loading, error, orders } = useSelector(state => state.myOrders);
    const { isCancel } = useSelector(state => state.cancel)
    const { isReturn } = useSelector(state => state.return)

    // console.log("ordaaaers", typeof cancelReason());
    // console.log("isCancel", isCancel);
    // if(orders){
    //     orders.forEach(order=>{
    //     console.log("orders",EID)
    //     console.log("orders",order.quantity)
    //     console.log("orders",order.totalPrice)
    //     console.log("orders",order.orderStatus)}
    //     )}
    useEffect(() => {
        dispatch(myOrders());
        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }
        if (isCancel) {
            alert.success("Product cancel successfully");
            navigate('/orders/me');
            dispatch({ type: CANCEL_ORDER_RESET })
        }

        if (isReturn) {
            alert.success("Product return successfully");
            navigate('/orders/return/me');
            dispatch({ type: RETURN_ORDER_RESET })
        }
    }, [dispatch, navigate, alert, error, isCancel, isReturn])

    const orderInvoiceHandler = (id, order_date) => {
        dispatch(invoicePdf(id, order_date));
    }

    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        //Update
        nameProduct: "",
        cancel: ""
    });

    const handleDialog = (message, isLoading, nameProduct, id) => {
        setDialog({
            message,
            isLoading,
            //Update
            nameProduct,
            id
        });
    };
    const cancelOrderHandler = (id, name) => {
        handleDialog("Are you sure you want to cancel order?", true, name, id);
    }

    const areUSureDelete = (choose) => {
        if (choose) {
            const formData = new FormData();
            formData.set('reason', cancelReason());
            var object = {};
            formData.forEach((value, key) => object[key] = value);
            var json = object
            dispatch(cancelOrder(dialog.id, json));
            handleDialog("", false);
        } else {
            handleDialog("", false);
        }
    }

    //reurn order
    const [dialog1, setDialog1] = useState({
        message: "",
        isLoading: false,
        //Update
        nameProduct: "",
        cancel: ""
    });

    const handleDialog1 = (message, isLoading, nameProduct, id) => {
        setDialog1({
            message,
            isLoading,
            //Update
            nameProduct,
            id
        });
    };
    const returnOrderHandler = (id, name) => {
        handleDialog1("Are you sure you want to return order?", true, name, id);
    }

    const areUSureDelete1 = (choose) => {
        if (choose) {
            const formData = new FormData();
            formData.set('reason', returnReason());
            var object = {};
            formData.forEach((value, key) => object[key] = value);
            var json = object
            dispatch(returnOrder(dialog1.id, json));
            handleDialog1("", false);
        } else {
            handleDialog1("", false);
        }
    }



    const setOrders = () => {
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Orders Name',
                    field: 'name',
                    sort: 'asc',
                    width: 250
                },
                {
                    label: 'Order Date',
                    field: 'date',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Price',
                    field: 'price',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Payment',
                    field: 'payment',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Delivery Status',
                    field: 'status',
                    sort: 'asc',
                    width: 150
                },
                // {
                //     label: 'Delivered Date',
                //     field: 'delivered_date',
                //     sort: 'asc',
                //     width: 150
                // },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'asc',
                    width: 150
                }

            ],
            rows: []
        }
        orders && orders.forEach(order => {
            const EID = Buffer.from(`${order.id}`, 'binary').toString('base64')
            if (order.paymentStatus) {
                data.rows.push({
                    id: order.order_id,
                    name: <Link className='proda' to={`/product/${Buffer.from(order.product_id.toString(), 'binary').toString('base64')}?${encodeURIComponent(order.productName)}`}>{order.productName + " Qty(" + order.quantity + ")"}</Link>,
                    status: order.orderStatus && String(order.orderStatus).includes('Delivered')
                        ? <p style={{ color: 'green' }}>{order.orderStatus}</p>
                        : <p style={{ color: 'red' }}>{String(order.paymentStatus).includes('Success') ? order.orderStatus : "-"}</p>,
                    price: `${order.sale_price}`,
                    payment: order.paymentStatus && String(order.paymentStatus).includes('Success')
                        ? <p style={{ color: 'green' }}>{order.paymentStatus}</p>
                        : <p style={{ color: 'red' }}>{order.paymentStatus ? order.paymentStatus : "NOT PAID"}</p>,
                    date: new Date(String(order.order_date)).toLocaleString('en-IN'),
                    // delivered_date: new Date(String(order.delivered_date)).toLocaleString('en-IN'),
                    actions: <Fragment><Link to={`/order/${EID}`} className="btn btn-primary">
                        <i className="fa fa-eye"></i>
                    </Link>
                        {String(order.paymentStatus).includes('Success') ? (<>
                            <button className="btn btn-info ml-2" title='print' onClick={() => orderInvoiceHandler(EID, order.order_date)}>
                                <i className="fa fa-print" aria-hidden="true"></i>
                            </button>
                            {order.orderStatus === "Delivered" ? (
                                (new Date() - new Date(order.order_date)) / 86400000 > 7 ? ("") : (
                                    <button className="btn btn-secondary mt-1 ml-2 "
                                        title='You only have 7 days to exchange the item or receive a refund.'
                                        disabled={(new Date() - new Date(order.order_date)) / 86400000 > 7 ? true : false}
                                        onClick={() => returnOrderHandler(EID, order.productName)}>
                                        <span>Return</span>
                                    </button>
                                )

                            ) : (
                                <button className="btn btn-secondary mt-1 ml-2 "
                                    disabled={order.orderStatus === "Delivered" ? true : false}
                                    onClick={() => cancelOrderHandler(EID, order.productName)}>
                                    <span>Cancel</span>
                                </button>
                            )}

                        </>) : ("")}

                    </Fragment>
                })
            }
        })
        return data;
    }
    return (
        <Fragment>
            <MetaData title={'My Orders'} />
            <div className='my-5'></div>
            <h4 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-5"><Link to={'/orders/me'} className='menuorder'>My Orders</Link></h4>
            <h4 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-5"><Link to={'/orders/cancel/me'} className='menuorder1'>Cancel Orders</Link></h4>
            <h4 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-5"><Link to={'/orders/return/me'} className='menuorder1'>Return Orders</Link></h4><hr />
            <br />
            {loading ? <Loader /> : (
                <MDBDataTable
                    data={setOrders()}
                    className='cust-table px-md-5 mt-20 mr-2 ml-2'
                    bordered
                    striped
                    hover scrollX
                />)}
            {dialog.isLoading && (
                <Dialog1
                    //Update
                    nameProduct={dialog.nameProduct}
                    onDialog={areUSureDelete}
                    message={dialog.message}
                    id={dialog.id}
                />
            )
            }

            {dialog1.isLoading && (
                <Dialog2
                    //Update
                    nameProduct={dialog1.nameProduct}
                    onDialog={areUSureDelete1}
                    message={dialog1.message}
                    id={dialog1.id}
                />
            )
            }
        </Fragment >
    )
}

export default ListOrders
