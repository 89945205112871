import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

const MetaData = ({ title, content }) => {
    return (
        <HelmetProvider>
            <Helmet>
                <title>{`${title} - Industry Central`}</title>
                <meta name="description" content={`${content}`} />
            </Helmet>
        </HelmetProvider>
    )
}

export default MetaData
