import React from 'react'

const Copyright = () => {
    return (
        <div className='policy container-fluid text-justify p-5'>
            <h1 className='text-center'>COPYRIGHT</h1>
            <ol type='A'>
                <h2><li>INTELLECTUAL PROPERTY RIGHTS POLICY</li></h2>
                <ol>
                    <li>
                        This site is controlled and operated by Indicent Bulk Supply Pvt. Ltd. and products are sold by respective Sellers. All material on this site, including images, illustrations, audio clips, and video clips, are protected by copyrights, trademarks, and other intellectual property rights. Material on Platform is solely for Your personal, non-commercial use. You must not copy, reproduce, republish, upload, post, transmit or distribute such material in any way, including by email or other electronic means and whether directly or indirectly and You must not assist any other person to do so. Without the prior written consent of the owner, modification of the materials, use of the materials on any other Platform or networked computer environment or use of the materials for any purpose other than personal, non-commercial use is a violation of the copyrights, trademarks and other proprietary rights, and is prohibited. Any use for which You receive any remuneration, whether in money or otherwise, is a commercial use for the purposes of this clause.
                    </li>
                    <li>
                        You are not permitted to use the Marks without the prior consent of Indicent Bulk Supply Pvt. Ltd., the vendor or the third party that may own the Marks. If you do so, Indicent Bulk Supply Pvt. Ltd. may, in its sole discretion, terminate Your Account.
                        All Marks, domain name, trade dress including the "look and feel" of the Website, its text, graphics, button icons, interfaces, etc., and the arrangements thereof, is the property of Indicent Bulk Supply Pvt. Ltd. or its vendors or the third party, as the case may be, unless otherwise indicated. All the contents are protected under copyright, trademark and other applicable intellectual property laws and may not be used by You, except as permitted by Indicent Bulk Supply Pvt. Ltd., its vendors or the concerned third party.
                    </li>
                </ol>
            </ol>
        </div>
    )
}

export default Copyright