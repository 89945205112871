import React, { Fragment, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'
import MetaData from '../layout/MetaData'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { getAdminProducts, topProduct } from '../../actions/productActions'
import { TOP_PRODUCT_RESET } from '../../constants/productConstants'

const ProductReviewsList = () => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { products } = useSelector(state => state.products);

    useEffect(() => {
        dispatch(getAdminProducts());
    }, [dispatch, alert])

    const topProductHandler = (id) => {
        dispatch(topProduct(id))
        window.location.reload()
        navigate('/admin/reviews');
        alert.success('Top product has been updated successfully');
        dispatch({ type: TOP_PRODUCT_RESET })
    }

    const setProducts = () => {
        const data = {
            columns: [
                {
                    label: 'ID',
                    field: 'id',
                    sort: 'asc',
                    width: 100,

                },
                {
                    label: 'Name',
                    field: 'name',
                    sort: 'asc',
                    width: 440,
                },
                {
                    label: 'Ratings',
                    field: 'ratings',
                    sort: 'asc',
                    width: 120,
                },
                {
                    label: 'Reviews',
                    field: 'numOfReviews',
                    sort: 'asc',
                    width: 120,
                },
                {
                    label: 'Top Selling',
                    field: 'top',
                    width: 120,
                },
                {
                    label: 'Top Products',
                    field: 'main',
                    width: 120,
                },
            ],
            rows: []
        }
        if (products) {
            products.forEach(product => {
                const EID = Buffer.from(`${product.id}`, 'binary').toString('base64')
                data.rows.push({
                    id: <Link className='proda' to={`/product/${EID}?${encodeURIComponent(product.name)}`}>{EID}</Link>,
                    name: `${product.name}`,
                    ratings: `${product.ratings ? product.ratings : ""}`,
                    numOfReviews: product.numOfReviews,
                    top: product.top,
                    main: <Fragment>
                        {product.main === 1 ? (<button className="btn btn-light  py-1 px-2 ml-2" title="Click to remove from top products" onClick={() => topProductHandler(EID)} >
                            <i className="fa fa-star" aria-hidden="true"></i>
                        </button>) : (
                            <button className="btn btn-light py-1 px-2 ml-2" title="Click to view on top products" onClick={() => topProductHandler(EID)} >
                                <i className="fa fa-star-o" aria-hidden="true"></i>
                            </button>
                        )}
                    </Fragment>
                })
            })
        }
        return data;
    }


    return (
        <Fragment>
            <MetaData title={'Product Reviews'} />
            <div className="row">
                <div className='col-12 col-md-12'>
                    <br />
                    <br />
                    <h2 className='center d-inline-flex p-2'>All Products</h2>
                    <br />
                    <Fragment>
                        {products ? (
                            <MDBDataTable
                                data={setProducts()}
                                className="cust-table px-3"
                                bordered
                                striped
                                hover scrollX
                                exportToCSV
                            />) : ""
                        }
                    </Fragment>
                </div>
            </div>
        </Fragment>
    )
}

export default ProductReviewsList
