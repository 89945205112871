import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import Search from "./Search";
import { useAlert } from 'react-alert'
import "../../App.css";
import { logout } from "../../actions/userActions";
import { allOrders } from '../../actions/orderActions'
import { EmailIcon } from "react-share";
import { gapi } from 'gapi-script';
import { GoogleLogout } from "react-google-login";
const clientId = '860003330133-4lo989olsuvjun9hhf1krr6lcm3mb1mu.apps.googleusercontent.com';


const Header = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const { user, loading } = useSelector(state => state.auth)
  const { cartItems } = useSelector(state => state.cart)
  const { orders, returnOrders } = useSelector(state => state.allOrders)

  const newArrivalsOrders = orders ? orders.filter(value => value.orderStatus === 'Processing' && value.paymentStatus === 'Success') : [];
  const newReturnOrders = returnOrders ? returnOrders.filter(value => value.orderStatus === 'Return') : [];

  useEffect(() => {
    if (user && Object.keys(user).length > 0 && user.role === 'admin') {
      dispatch(allOrders());
    }
  }, [user, dispatch]);

  const logoutHandler = () => {
    dispatch(logout());
    // handleLogout();
    alert.success('Logged out successfully.')

  }

  const handleLogout = () => {
    const auth2 = gapi.auth2.getAuthInstance();
    auth2.signOut().then(() => {
      console.log('User signed out');
      // Perform any additional actions after logout

      // Disconnect the user, which revokes the authorization and clears session
      auth2.disconnect();
    });
  };

  return (
    <Fragment>
      <nav className="row contact1">
        <div className="contact ml-auto mr-3 p-1">
          <ul className="list-unstyled list-group list-group-horizontal">
            <a className="text-dark bg-muted" href="mailto:sales@indicent.co.in">
              <li className="inline-block mr-3"> <EmailIcon size={16} round /> sales@indicent.co.in</li>
            </a>
            <a className="text-dark bg-muted" target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=919970399958&lang=en&text=Hii...! Welcome to Indicent Bulk Supply Pvt Ltd.">
              <li className="inline-block mr-3"><img src="/images/whatsapp.png" loading="lazy" alt="whatsapp" width="17" height="17" />+91 9970399958</li>
            </a>
            <a className="text-dark bg-muted" href="/enquiry"> <li className="inline-block mr-3"><i className="fa fa-shopping-bag " width="17" height="17"></i> Bulk Order</li></a>
          </ul>
        </div>
      </nav>

      <nav className="navbar row sticky-top slider-nav-bg" style={{ padding: "20px 0" }}>
        <div className="col-6 col-md-3">
          <div className="navbar-brand">
            <Link to="/">
              <img src="/images/logo.png" alt="Indicent Bulk Supply Pvt Ltd Logo" className="img-fluid" style={{ maxWidth: "180px", maxHeight: "180px" }} />
            </Link>
          </div>
        </div>

        <div className={user && user.role === 'admin' ? "col-12 col-md-4 mt-2 mt-md-0" : "col-12 col-md-6 mt-2 mt-md-0"}>
          <Search />
        </div>

        <div className={user && user.role === 'admin' ? "col-12 col-md-5 mmb-mobile text-center" : "col-12 col-md-3 mmb-mobile text-center"}>
          {user && user.role === 'admin' ? (
            <>
              <Link to="/admin/orders/return" className="d-inline-block ">
                <i className="fa fa-shopping-basket fa-3x" id="cart_count" aria-hidden="true">
                  <sup className="cart-sup" id="cart_count1">{orders ? newReturnOrders.length : 0}</sup>
                  <span id="cart" className="cartim d-none d-md-inline">Return Orders</span>
                </i>
              </Link>

              <Link to="/admin/orders" className="d-inline-block ">
                <i className="fa fa-shopping-bag fa-3x" id="cart_count" aria-hidden="true">
                  <sup className="cart-sup" id="cart_count1">{orders ? newArrivalsOrders.length : 0}</sup>
                  <span id="cart" className="cartim d-none d-md-inline">Orders</span>
                </i>
              </Link>
            </>
          ) : (
            <Link to="/cart" className="d-inline-block ">
              <i className="fa fa-cart-arrow-down fa-3x" id="cart_count" aria-hidden="true">
                <sup className="cart-sup" id="cart_count1">{cartItems.length}</sup>
                <span id="cart" className="cartim d-none d-md-inline">Cart</span>
              </i>
            </Link>
          )}

          {user ? (
            <div className="ml-4 dropdown d-inline">
              <Link to="#!" className="btn dropdown-toggle mr-4" type="button" id="dropDownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <figure className="avatar avatar-nav">
                  <img src={user && user.imageName ? `/${user.imageName}` : '/images/default_avatar.jpg'} className="rounded-circle" loading="lazy" alt="" />
                </figure>
                <span>{user && user.name}</span>
              </Link>
              <div className="dropdown-menu mt-2" aria-labelledby="dropDownMenuButton">
                {user && user.role === 'admin' && (
                  <Link className="dropdown-item" to="/dashboard">Dashboard</Link>
                )}
                <Link className="dropdown-item" to="/orders/me">Order</Link>
                <Link className="dropdown-item" to="/me">Profile</Link>
                <Link className="dropdown-item" to="/" onClick={logoutHandler}>Logout</Link>
              </div>
            </div>
          ) : (!loading && <Link to="/login" className="btn ml-4" id="login_btn">Login</Link>)}
        </div>
      </nav>

    </Fragment >
  );
};

export default Header;
