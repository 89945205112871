export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'

export const REGISTER_USER_REQUEST = 'REGISTER_USER_REQUEST'
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL'

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_FAIL = 'LOAD_USER_FAIL'

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST'
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_RESET = 'UPDATE_PROFILE_RESET'
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL'

export const UPDATE_PASSWORD_REQUEST = 'UPDATE_PASSWORD_REQUEST'
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS'
export const UPDATE_PASSWORD_RESET = 'UPDATE_PASSWORD_RESET'
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL'

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL'

export const NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST'
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS'
export const NEW_PASSWORD_FAIL = 'NEW_PASSWORD_FAIL'

export const ALL_USERS_REQUEST = 'ALL_USERS_REQUEST'
export const ALL_USERS_SUCCESS = 'ALL_USERS_SUCCESS'
export const ALL_USERS_FAIL = 'ALL_USERS_FAIL'

export const USER_DETAILS_REQUEST = 'USER_DETAILS_REQUEST'
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS'
export const USER_DETAILS_FAIL = 'USER_DETAILS_FAIL'

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_RESET = 'UPDATE_USER_RESET'
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL'

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_RESET = 'DELETE_USER_RESET'
export const DELETE_USER_FAIL = 'DELETE_USER_FAIL'

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGOUT_FAIL'


export const ALL_ADDRESS_REQUEST = 'ALL_ADDRESS_REQUEST'
export const ALL_ADDRESS_SUCCESS = 'ALL_ADDRESS_SUCCESS'
export const ALL_ADDRESS_FAIL = 'ALL_ADDRESS_FAIL'

export const ADDRESS_DETAILS_REQUEST = 'ADDRESS_DETAILS_REQUEST'
export const ADDRESS_DETAILS_SUCCESS = 'ADDRESS_DETAILS_SUCCESS'
export const ADDRESS_DETAILS_FAIL = 'ADDRESS_DETAILS_FAIL'

export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST'
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS'
export const ADD_ADDRESS_FAIL = 'ADD_ADDRESS_FAIL'
export const ADD_ADDRESS_RESET = 'ADD_ADDRESS_RESET'


export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST'
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS'
export const UPDATE_ADDRESS_RESET = 'UPDATE_ADDRESS_RESET'
export const UPDATE_ADDRESS_FAIL = 'UPDATE_ADDRESS_FAIL'

export const DELETE_ADDRESS_REQUEST = 'DELETE_ADDRESS_REQUEST'
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS'
export const DELETE_ADDRESS_RESET = 'DELETE_ADDRESS_RESET'
export const DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL'

export const DEFAULT_ADDRESS_REQUEST = 'DEFAULT_ADDRESS_REQUEST'
export const DEFAULT_ADDRESS_SUCCESS = 'DEFAULT_ADDRESS_SUCCESS'
export const DEFAULT_ADDRESS_FAIL = 'DEFAULT_ADDRESS_FAIL'
export const DEFAULT_ADDRESS_RESET = 'DEFAULT_ADDRESS_RESET'

export const EMAIL_VERIFICATION_REQUEST = 'EMAIL_VERIFICATION_REQUEST'
export const EMAIL_VERIFICATION_SUCCESS = 'EMAIL_VERIFICATION_SUCCESS'
export const EMAIL_VERIFICATION_FAIL = 'EMAIL_VERIFICATION_FAIL'
export const EMAIL_VERIFICATION_RESET = 'EMAIL_VERIFICATIONSETEST'


export const GET_DEFAULT_ADDRESS_REQUEST = 'GET_DEFAULT_ADDRESS_REQUEST'
export const GET_DEFAULT_ADDRESS_SUCCESS = 'GET_DEFAULT_ADDRESS_SUCCESS'
export const GET_DEFAULT_ADDRESS_FAIL = 'GET_DEFAULT_ADDRESS_FAIL'
export const GET_DEFAULT_ADDRESS_RESET = 'GET_DEFAULT_ADDRESS_RESET'

export const SHARE_COUPON_REQUEST = 'SHARE_COUPON_REQUEST'
export const SHARE_COUPON_SUCCESS = 'SHARE_COUPON_SUCCESS'
export const SHARE_COUPON_FAIL = 'SHARE_COUPON_FAIL'

export const NEW_ENQUIRY_REQUEST = 'NEW_ENQUIRY_REQUEST'
export const NEW_ENQUIRY_SUCCESS = 'NEW_ENQUIRY_SUCCESS'
export const NEW_ENQUIRY_FAIL = 'NEW_ENQUIRY_FAIL'

export const GET_ENQUIRY_REQUEST = 'GET_ENQUIRY_REQUEST'
export const GET_ENQUIRY_SUCCESS = 'GET_ENQUIRY_SUCCESS'
export const GET_ENQUIRY_FAIL = 'GET_ENQUIRY_FAIL'
export const GET_ENQUIRY_RESET = 'GET_ENQUIRY_RESET'

export const DELETE_INQUIRY_REQUEST = 'DELETE_INQUIRY_REQUEST'
export const DELETE_INQUIRY_SUCCESS = 'DELETE_INQUIRY_SUCCESS'
export const DELETE_INQUIRY_FAIL = 'DELETE_INQUIRY_FAIL'
export const DELETE_INQUIRY_RESET = 'DELETE_INQUIRY_RESET'


export const CLEAR_ERRORS = 'CLEAR_ERRORS'
