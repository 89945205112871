import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MDBDataTable } from 'mdbreact'

import MetaData from '../layout/MetaData'
import Loader from '../layout/Loader'
import Sidebar from './Sidebar'

import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { allOrders, deleteOrder, invoicePdf, clearErrors } from '../../actions/orderActions'
import { DELETE_ORDER_RESET } from '../../constants/orderConstants'
import Dialog from "../../Dialog";
import { CSVLink } from 'react-csv';

const OrderFailList = () => {
    const navigate = useNavigate();
    const alert = useAlert();
    const dispatch = useDispatch();

    const { loading, error, failOrders } = useSelector(state => state.allOrders);
    // console.log("orders45", orders);
    const { isDeleted } = useSelector(state => state.order)

    const [list, setList] = useState({ display: "block" });
    const hide = () => {
        if (list.display === "block") {
            setList({ display: "none" })
        } else {
            setList({ display: "block" })
        }
    }

    useEffect(() => {
        dispatch(allOrders());

        if (error) {
            alert.error(error);
            dispatch(clearErrors())
        }

        if (isDeleted) {
            alert.success('Order deleted successfully');
            navigate('/admin/orders', { replace: true });
            dispatch({ type: DELETE_ORDER_RESET })
        }

    }, [dispatch, navigate, alert, error, isDeleted])

    const [dialog, setDialog] = useState({
        message: "",
        isLoading: false,
        //Update
        nameProduct: ""
    });

    const handleDialog = (message, isLoading, nameProduct, id) => {
        setDialog({
            message,
            isLoading,
            //Update
            nameProduct,
            id
        });
    };

    const orderInvoiceHandler = (id, order_date) => {
        dispatch(invoicePdf(id, order_date));
    }

    const deleteOrderHandler = (id, name) => {
        handleDialog("Are you sure you want to delete order?", true, name, id);
    }

    const areUSureDelete = (choose) => {
        if (choose) {
            dispatch(deleteOrder(dialog.id))
            handleDialog("", false);
        } else {
            handleDialog("", false);
        }
    }

    const csvData = JSON.parse(JSON.stringify(failOrders ? failOrders : []));
    const setOrders = () => {
        const data = {
            columns: [
                {
                    label: 'Order ID',
                    field: 'id',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Product',
                    field: 'name',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'GSTN No',
                    field: 'gstn',
                    sort: 'asc',
                    width: 160
                },
                {
                    label: 'Order Date',
                    field: 'date',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'User',
                    field: 'userName',
                    sort: 'asc',
                    width: 100
                },

                {
                    label: 'Price',
                    field: 'price',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Payment',
                    field: 'payment',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Delivery Status',
                    field: 'status',
                    sort: 'asc',
                    width: 100
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    width: 120
                },
            ],
            rows: []
        }

        failOrders && failOrders.forEach(order => {
            if (order.paymentStatus) {
                const EID1 = Buffer.from(`${order.id}`, 'binary').toString('base64')
                data.rows.push({
                    id: order.order_id,
                    date: new Date(String(order.order_date)).toLocaleString('en-IN'),
                    userName: order.userName,
                    gstn: order.gstn,
                    name: <Link className='proda' to={`/product/${Buffer.from(`${order.product_id}`, 'binary').toString('base64')}?${encodeURIComponent(order.productName)}`}>{order.productName + " (Qty:" + order.quantity + ")"}</Link>,
                    price: `₹${order.sale_price}`,
                    status: order.orderStatus && String(order.orderStatus).includes('Delivered')
                        ? <p style={{ color: 'green' }}>{order.orderStatus}</p>
                        : <p style={{ color: 'red' }}>{String(order.paymentStatus).includes('Success') ? order.orderStatus : "-"}</p>,
                    payment: order.paymentStatus && String(order.paymentStatus).includes('Success')
                        ? <p style={{ color: 'green' }}>{order.paymentStatus}</p>
                        : <p style={{ color: 'red' }}>{order.paymentStatus ? order.paymentStatus : "NOT PAID"}</p>,
                    actions: <Fragment>
                        <button onClick={() => { window.location.href = `/admin/order/${EID1}` }} className="btn btn-primary ml-2 mb-2">
                            <i className="fa fa-eye"></i>
                        </button>
                        {String(order.paymentStatus).includes('Success') ? (<>
                            <button className="btn btn-info ml-2 mb-2" title='print' onClick={() => orderInvoiceHandler(EID1, order.order_date)}>
                                <i className="fa fa-print" aria-hidden="true"></i>
                            </button>
                        </>) : ("")}
                        {/* <button className="btn btn-danger py-1 px-2 ml-2" onClick={() => deleteOrderHandler(EID1, order.name)}>
                            <i className="fa fa-trash"></i>
                        </button> */}
                    </Fragment>
                })
            }
        })

        return data;
    }

    return (
        <Fragment>
            <MetaData title={'All Orders'} />
            <div className="row">
                <div style={list} className="col-12 col-md-2">
                    <Sidebar />
                </div>

                <div className={`${list.display === 'block' ? "col-12 col-md-10" : "col-12 col-md-12"}`}>
                    <Fragment>
                        <div className='my-4'></div>
                        <button className="mt-3 proda menuorder" onClick={hide}><i className="fa fa-bars" aria-hidden="true"></i></button>
                        <h3 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-3"><Link to={'/admin/orders'} className='menuorder1'>All Orders</Link></h3>
                        <h3 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-2"><Link to={'/admin/orders/cancel'} className='menuorder1'>Cancel Orders</Link></h3>
                        <h3 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-2"><Link to={'/admin/orders/return'} className='menuorder1'>Return Orders</Link></h3>
                        <h3 style={{ display: 'inline' }} className="mt-5 ml-2 px-md-2"><Link to={'/admin/orders/fail'} className='menuorder'>Fail Orders</Link></h3>
                        <h5 style={{ display: 'inline' }} className="marginleft mr-1 mt-5 px-md-2"> <CSVLink className='menuorder' data={csvData}
                            filename={"orders.csv"} target="_blank"  >Download</CSVLink></h5>
                        <br />
                        <br />

                        {loading ? <Loader /> : (
                            <MDBDataTable
                                data={setOrders()}
                                className="px-3"
                                bordered
                                striped
                                hover scrollX
                                exportToCSV
                            />
                        )}
                    </Fragment>
                </div>
                {dialog.isLoading && (
                    <Dialog
                        //Update
                        nameProduct={dialog.nameProduct}
                        onDialog={areUSureDelete}
                        message={dialog.message}
                        id={dialog.id}
                    />
                )}
            </div>
        </Fragment>
    )
}

export default OrderFailList
