import React, { Fragment, useState, useEffect } from 'react'

import MetaData from '../layout/MetaData'
import { useNavigate } from "react-router-dom";
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { updateProfile, loadUser, clearErrors } from '../../actions/userActions'
import { UPDATE_PROFILE_RESET } from '../../constants/userConstants'
import { useForm } from "react-hook-form";

const UpdateProfile = () => {
    const navigate = useNavigate();
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [avatar, setAvatar] = useState()
    const [mobile, setMobile] = useState()
    const [file, setFile] = useState()
    const [avatarPreview, setAvatarPreview] = useState('/images/default_avatar.jpg')
    const { register, handleSubmit, formState: { errors }, reset, trigger } = useForm();

    const alert = useAlert();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { error, isUpdated, loading } = useSelector(state => state.user)

    useEffect(() => {
        if (user) {
            setName(user.name);
            setEmail(user.email);
            setMobile(user.mobile);
            setAvatarPreview(user.imageName)
        }
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (isUpdated) {
            alert.success('User updated successfully')
            dispatch(loadUser());
            navigate('/me');
            dispatch({
                type: UPDATE_PROFILE_RESET
            })
        }
    }, [dispatch, navigate, alert, error, isUpdated, user])

    const submitHandler = (data, e) => {
        e.preventDefault();

        // if (!file) {
        //     setFile(avatarPreview)
        // }

        const formData = new FormData();
        formData.set('name', name);
        formData.set('email', email);
        formData.set('mobile', mobile);
        formData.append(`file`, file)


        // var object = {};
        // formData.forEach((value, key) => object[key] = value);
        // var json = object
        // console.log(json)

        // dispatch(updateProfile(formData))
        dispatch(updateProfile(formData));
        reset();
    }

    const onChange = event => {
        if (event.target.name === 'file') {
            const file = event.target.files[0];
            setFile(file);

            if (event.target.files.length === 0) {
                window.alert("Please select a image");
                return false;
            }

            if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
                window.alert("File does not support. You must use .png or .jpg ");
                return false;
            }
            if (file.size > 4000000) {
                window.alert("Please upload a image smaller than 4 MB");
                return false;
            }


            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setAvatarPreview(reader.result)
                    setAvatar(reader.result)
                }
            }

            reader.readAsDataURL(event.target.files[0])
        }
    }
    return (
        <Fragment>
            <MetaData title={'Update Profile'} />

            <div className="row wrapper">
                <div className="col-10 col-lg-5">
                    <form className="shadow-lg" onSubmit={handleSubmit(submitHandler)} encType='multipart/form-data'>
                        <h1 className="mt-2 mb-5">Update Profile</h1>

                        <div className="form-group">
                            <label htmlFor="email_field" className="label-dark">Name</label>
                            <input
                                type="name"
                                id="name_field"
                                className="form-control"
                                name='name'
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label htmlFor="email_field" className="label-dark">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                name='email'
                                value={email}
                                readOnly
                            />
                            <p className="text-muted description">This email address cannot be changed.</p>
                        </div>

                        <div className="form-group">
                            <label htmlFor="mobile_field" className="label-dark">Mobile Number</label>
                            <input
                                type="text"
                                id="mobile_field"
                                className={`form-control ${errors.mobile && "invalid"}`}
                                {...register("mobile", {
                                    pattern: {
                                        value: /^(?:(?:\+|0{0,2})91(\s*[ -]\s*)?|[0]?)?[789]\d{9}$/g,
                                        message: "Invalid Mobile Number",
                                    },
                                    maxLength: {
                                        value: 15,
                                        message: "Invalid Mobile Number",
                                    }

                                })}
                                oninvalid={() => {
                                    trigger("mobile");
                                }}
                                name='mobile'
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                required
                            />
                            {errors.mobile && (
                                <small className="text-danger">{errors.mobile.message}</small>
                            )}
                        </div>

                        <div className='form-group'>
                            <label htmlFor='avatar_upload' className="label-dark">Avatar</label>
                            <div className='d-flex align-items-center'>
                                <div>
                                    <figure className='avatar mr-3 item-rtl'>
                                        <img
                                            src={`/${avatarPreview}`}
                                            className='rounded-circle'
                                            alt='Avatar Preview'
                                        />
                                    </figure>
                                </div>
                                <div className='custom-file'>
                                    <input
                                        type='file'
                                        name='file'
                                        className='custom-file-input'
                                        id='customFile'
                                        accept='image/*'
                                        onChange={onChange}
                                    />
                                    <label className='custom-file-label label-dark' htmlFor='customFile'>
                                        Choose Avatar
                                    </label>
                                </div>
                            </div>
                        </div>

                        <button type="submit" className="btn update-btn btn-block mt-4 mb-3"
                            disabled={loading ? true : false} >Update</button>
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default UpdateProfile
