import React, { Fragment, useState, useEffect, useRef } from "react";
import Pagination from "react-js-pagination";
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css';
import { Link, useParams } from "react-router-dom";
import MetaData from "./layout/MetaData";
import CategoryMenu from "./layout/Menu";
import Nav1 from "./layout/Nav1";
import Product from './product/Product'
import Loader from './layout/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { useAlert } from 'react-alert';
import { getProducts, allCategory, getSideImage, getSiderImage } from "../actions/productActions";
import { Carousel } from 'react-bootstrap'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import RelatedItemHome from "./product/RelatedItemHome";
import Slider1 from "react-slick";
import Checkbox from '@mui/material/Checkbox';
import { Menu } from "@mui/material";
const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range)

const Home = () => {
  const params = useParams();
  const contentRef = useRef(null);
  const [currentPage, setcurrentPage] = useState(1)
  const [discountPrice, setDiscountPrice] = useState([1, 1000000])
  const { category } = useSelector(state => state.allCategory);
  const { sideImages } = useSelector((state) => state.allSideImage);
  const { siderImages } = useSelector((state) => state.allSiderImage);
  const [categoryName, setCategoryName] = useState('')
  const [sort, setSort] = useState('')
  const [rating, setRating] = useState(0)
  const [btnActive, setBtnActive] = useState('');
  const [btnActive1, setBtnActive1] = useState('');
  const [btnActive2, setBtnActive2] = useState('');

  const alert = useAlert();
  const dispatch = useDispatch();
  const { loading, products, error, productsCount, resPerPage, filteredProductsCount, productsTop } = useSelector(state => state.products)
  const keyword = params.keyword

  // console.log("filteredProductsCount", filteredProductsCount);
  // console.log("currentPage", currentPage);

  const fetchProducts = () => {
    dispatch(getProducts(keyword, currentPage, discountPrice, categoryName, rating, sort));
    dispatch(allCategory());
    dispatch(getSideImage());
    dispatch(getSiderImage());
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
    }

    fetchProducts();
  }, [dispatch, alert, error, keyword, currentPage, discountPrice, categoryName, rating, sort]);

  function setCurrentPageNo(pageNumber) {
    setcurrentPage(pageNumber)
    scrollTop()
  }


  let count = productsCount;
  if (keyword) {
    count = filteredProductsCount
  }
  let settings = {
    speed: 1000,
    infinite: true,
    autoplay: true,
    arrows: true,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          speed: 1000,
          infinite: true,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          speed: 1000,
          infinite: true,
          autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const popular = () => {
    setSort('DescTop');
    setBtnActive('btn-active sort-btnactive')
    setBtnActive1('')
    setBtnActive2('')
  }
  const low = () => {
    setSort('Asc');
    setBtnActive('')
    setBtnActive1('btn-active sort-btnactive')
    setBtnActive2('')

  }
  const high = () => {
    setSort('Desc');
    setBtnActive('')
    setBtnActive1('')
    setBtnActive2('btn-active sort-btnactive')
  }

  const products1 = { images: siderImages };
  function scrollTop() {
    const body = document.querySelector('#root');

    body.scrollIntoView({
      behavior: 'smooth'
    }, 500)
  }


  return (
    <div className="container-fluid slider-bg">
      <Fragment>
        {loading ? <Loader /> : (
          <Fragment>
            <MetaData title={'Buy Online for Industrial Tools, Safety Equipment, Power Tools & more - Industry Central'} content="Your page description" />
            {keyword ? ("") : (
              <div>

                <div className="row">
                  <div className="col-md-3 col-sm-12 mt-3">
                    {/* <CategoryMenu /> */}
                    <Nav1 />
                  </div>
                  <div className="col-md-7 col-sm-12 mt-3">
                    <div className="row f-flex justify-content-around">
                      <div className="img-fluid">
                        <Carousel interval={2000} >
                          {products1.images && products1.images.map(image => (
                            image.url ? (<Carousel.Item key={image.id}>
                              <img className="d-block banner-image" loading="lazy" src={image.url} alt={products1.keyword} />
                            </Carousel.Item>) : ""
                          ))}
                        </Carousel>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-2 col-sm-12 mt-3">
                    <div className="row justify-content-md-center">
                      <div className="col">
                        <Link to={{ pathname: `/products/${sideImages[0] ? sideImages[0].keyword : ""}` }}>
                          <img src={`/${sideImages[0] ? sideImages[0].url : ""}`} loading="lazy" alt="Drilling Machine" width="100%" height="120" />
                        </Link>
                      </div>
                    </div>

                    <div className="row justify-content-md-center mt-3">
                      <div className="col">
                        <Link to={{ pathname: `/products/${sideImages[1] ? sideImages[1].keyword : ""}` }}>
                          <img src={`/${sideImages[1] ? sideImages[1].url : ""}`} loading="lazy" alt="Cutters" width="100%" height="120" />
                        </Link>
                      </div>
                    </div>

                    <div className="row justify-content-md-center mt-3">
                      <div className="col">
                        <Link to={{ pathname: `/products/${sideImages[2] ? sideImages[2].keyword : ""}` }}>
                          <img src={`/${sideImages[2] ? sideImages[2].url : ""}`} loading="lazy" alt="Drills Machine" width="100%" height="120" />
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="row slider-bg">
                  <div className="container-fluid mt-3 mb-3">
                    <div className="bg-white related-slider"><br /><h3 className="related-heading">Top Selling Products</h3><br /></div>
                    <Slider1 {...settings} >
                      {productsTop &&
                        productsTop.map((product) => (
                          product.main === 1 ? (
                            <RelatedItemHome key={product.id} product={product} />
                          ) : ("")

                        ))}
                    </Slider1>
                  </div>
                </div>
              </div>
            )}


            <div class="row">
              <div class="col-md-3 mb-3">
                <Link to="/category/Tools" target="_blank">
                  <img src="/images/tools.jpg" alt="Tools" loading="lazy" title="Screwdrivers Category" class="img-fluid custom-img" />
                </Link>
              </div>
              <div class="col-md-3 mb-3">
                <Link to="/search/%20" target="_blank">
                  <img src="/images/grinding-wheel.jpg" loading="lazy" alt="Grinding Wheel" title="Grinding Wheel Category" class="img-fluid custom-img" />
                </Link>
              </div>
              <div class="col-md-3 mb-3">
                <Link to="/enquiry1" target="_blank">
                  <img src="/images/Screwdrivers.jpg" loading="lazy" alt="Screwdrivers" title="Tools Category" class="img-fluid custom-img" />
                </Link>
              </div>
              <div class="col-md-3 mb-3">
                <Link to="/register">
                  <img src="/images/enquiry.jpg" loading="lazy" alt="Enquiry" title="Bulk Order Enquiry" class="img-fluid custom-img" />
                </Link>
              </div>
            </div>


            <section id="products" ref={contentRef} className="container-fluid mt-1 bg-white">
              {keyword ? ("") : (<><br /><h1 id="products_heading">Latest Products</h1></>)}
              <div className="row">
                {keyword ? (
                  <Fragment>
                    <div className="col-sm-12 col-md-3 filter-apply my-5">
                      <div className="px-4 f1">
                        <Range
                          marks={{ 100: `₹100`, 50000: `₹50000` }}
                          min={100}
                          max={50000}
                          defaultValue={[100, 50000]}
                          tipFormatter={value => `Rs.${value}`}
                          tipProps={{ placement: "top", visible: true }}
                          value={discountPrice}
                          onChange={discountPrice => setDiscountPrice(discountPrice)}
                        />
                        <hr className="my-5" />
                        <div className="mt-5 scroll" >
                          <h4 className="mb-3">Categories</h4>
                          <ul className="pl-0">
                            {category.map(cat => (
                              <li style={{ cursor: 'pointer', listStyleType: 'none' }}>
                                <Checkbox key={cat.category} value={categoryName} onChange={() => setCategoryName(cat.category)} color="success" defaultChecked={cat.category === categoryName ? true : false} />
                                {cat.category}
                              </li>
                            ))}
                          </ul>
                        </div>
                        {/* ratings */}
                        <hr className="my-3" />
                        <div className="mt-5" >
                          <h4 className="mt-3">Select Ratings</h4>
                          <ul className="pl-0">
                            {[5, 4, 3, 2, 1].map(star => (
                              <li
                                style={{ cursor: 'pointer', listStyleType: 'none' }} className="rating-size" key={star} onClick={() => setRating(star)} >
                                <div className="rating-outer">
                                  <div className="rating-inner" style={{ width: `${star * 20}%` }}>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-9">
                      <div className="row">
                        {keyword ? (
                          <div className="row container">
                            <div className="bg-white serach-margin">
                              <div className="ml-2">
                                <h1 id="products_heading" className="serach-result">1-{filteredProductsCount} of over {productsCount} results for
                                  <span style={{ color: "#cc3f06" }}> "{keyword}"</span> </h1>
                                <hr />

                                <div className="filter-sort">
                                  <div className="text-right">
                                    <button type="button" className="btn"><b>SORT BY</b></button>
                                    <button type="button" className={`btn sort-btn ${btnActive}`} onClick={popular}>POPULARITY</button>
                                    <button type="button" className={`btn sort-btn ${btnActive1}`} onClick={low}>LOW TO HIGH PRICE</button>
                                    <button type="button" className={`btn sort-btn ${btnActive2}`} onClick={high}>HIGH TO LOW PRICE</button>
                                  </div>
                                </div>
                                <br />
                              </div>
                            </div>
                          </div>
                        )
                          : ("")}
                        {products && products.map(product => (
                          <Product key={product.id} product={product} col={3} />
                        ))}
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  products && products.map(product => (
                    <Product key={product.id} product={product} col={2} />
                  ))
                )}
              </div>
            </section>
            {resPerPage <= count && (
              <div className="d-flex justify-content-center mt-5">
                <Pagination
                  activePage={currentPage}
                  itemsCountPerPage={resPerPage}
                  totalItemsCount={productsCount}
                  onChange={setCurrentPageNo}
                  nextPageText={'Next'}
                  prevPageText={'Prev'}
                  firstPageText={'First'}
                  lastPageText={'Last'}
                  itemClass="page-item"
                  linkClass="page-link"
                />
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    </div>
  );
};

export default Home;
