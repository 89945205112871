import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import MetaData from '../layout/MetaData'
import CheckoutSteps from './CheckoutSteps'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { statusReqHandler, clearErrors } from '../../actions/orderActions'
import { useNavigate, useParams } from "react-router-dom";
const querystring = require('querystring')
const Payment = () => {
    useEffect(() => {

        return () => {
            setTimeout(orderSuccess, 0)
        }

    }, [])

    function orderSuccess() {
        sessionStorage.removeItem('orderInfo')
    }

    // const dispatch = useDispatch();


    // useEffect(() => {

    // }, [dispatch])


    // const submitHandler = async (e) => {

    //     const formData = new FormData();
    //     formData.set("encRequest", encRequest);
    //     formData.set("access_code", access_code);
    //     formData.set("request_type", 'JSON');
    //     formData.set("response_type", "JSON");
    //     formData.set("command", "orderStatusTracker");
    //     formData.set("version", 1.2);

    //     var object = {};
    //     formData.forEach((value, key) => object[key] = value);
    //     var json = object


    //     dispatch(statusReqHandler(querystring.stringify(json)));

    //     // navigate('/success');

    // }
    return (
        <div className="container-fluid">
            <Fragment>
                <MetaData title={'Cancel Payment'} />
                {/* <CheckoutSteps shipping confirmOrder payment /> */}
                <div className="row wrapper">
                    <div className="col-12 col-lg-10">

                        {/* <form id="nonseamless" onSubmit={submitHandler()} >
                            <input type="hidden" id="encRequest" name="encRequest" value={`${encRequest}`} />
                            <input type="hidden" name="access_code" id="access_code" value="AVJA40KL60BD86AJDB" />
                            <button type='submit' id="checkout_btn" className="btn btn-primary btn-block">Confirm</button>
                        </form> */}

                        <div className="row justify-content-center">
                            <div className="col-6 text-center">
                                <img className="img-fluid d-block mx-auto" src="/images/cancel.png" alt="Order cancel" width="200" height="200" />

                                <h1 className='text-danger'>Payment Cancel!</h1>
                                <hr />

                                <Link to="/" className='menuorder'>Home...!</Link>
                                <hr />
                            </div>
                        </div>

                    </div>
                </div>
            </Fragment>
        </div>
    )
}
export default Payment
