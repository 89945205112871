import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import MetaData from '../layout/MetaData'

const OrderError = () => {
    return (
        <div className="container-fluid">
            <Fragment>
                <MetaData title={'Order Success'} />
                <div className="row justify-content-center">
                    <div className="col-6 mt-5 text-center">
                        <img className="my-5 img-fluid d-block mx-auto" src="/images/fail.png" alt="Order Fail" width="200" height="200" />

                        <h1 className='text-danger'>Tranaction Failed!</h1>
                        <h6 >The transaction failed due to technical error. If your money was debited, you should get a refund.</h6>
                        {/* <h6 >If there is any problem, feel free to contact us <a href="tel:+4733378901"> +91 9156657604</a></h6><hr /> */}

                        <Link to="/" className='menuorder'>Go to Back...!</Link>
                    </div>
                </div>
            </Fragment>
        </div>
    )
}

export default OrderError
